<template>
  <nav>

    <div class="nav-menu">
  
      <div>
        <img src="../assets/images/icons/logo.svg" class="nav-logo" v-on:click="goTo('buscar')"/>
      </div>
        
      <div class="nav-links">
        <div :class="{ 'nav-active': currentSection === 'Preguntas Frecuentes' }" v-on:click="goTo('preguntas')"><img src="../assets/images/icons/interrogation.svg"/>Preguntas Frecuentes</div>
        <div :class="{ 'nav-active': currentSection === 'Buscar Locacion' }" v-on:click="goTo('buscar')"><img src="../assets/images/icons/marker.svg"/>Buscar Locación</div>
        <div :class="{ 'nav-active': currentSection === 'Sube tu locacion' }" v-on:click="goTo('subir-locacion')" id="subeTuLocacionPc"><img src="../assets/images/icons/building.svg"/><p>Sube tu Locación</p></div>
        <div class="account navAccount" v-on:click="goTo('crear-cuenta')">Crea tu cuenta / Inicia Sesión<img src="../assets/images/icons/userpic.svg"/></div>
        <div class="account navProfile" v-on:click="goTo('perfil')" style="display: none;"><span class="nav-username">usrprofile</span><img src="../assets/images/icons/userpic.svg" class="nav-userpic"/></div>
        <NotificationsModule v-if="status" class="nav-notifications-btn"/>
      </div>

      <div class="mobile-nav">
        <button v-on:click="goTo('crear-cuenta')" class="navAccount"><img src="../assets/images/icons/user.svg"/>Inicia sesión</button>
        <div  class="userpic-cel nav-userpic navProfile" v-on:click="goTo('perfil')">
          Mi perfil <img src="../assets/images/icons/userpic.svg" class="nav-userpic navProfile"/>
        </div>
      </div>

    </div>

    <div class="nav-fixed">
      <div :class="{ 'nav-active': currentSection === 'Preguntas Frecuentes' }" v-on:click="goTo('preguntas')"><img src="../assets/images/icons/interrogation.svg"/>Preguntas Frecuentes</div>
      <div :class="{ 'nav-active': currentSection === 'Buscar Locacion' }" v-on:click="goTo('perfil')"><!--<img src="../assets/images/icons/marker.svg"/>Buscar Locación--><img src="../assets/images/icons/userpic.svg" class="nav-userpic navProfile mi-perfil-cel"/> Mi<br>Perfil</div>
      <div :class="{ 'nav-active': currentSection === 'Sube tu locacion' }" v-on:click="goTo('subir-locacion')" id="subeTuLocacionCel"><img src="../assets/images/icons/building.svg"/>Sube tu Locación</div>
    </div>

    <div class="nav-space"></div>
  
  </nav>
</template>
  
<script>
import NotificationsModule from './NotificationsModule.vue';
export default {
  components: { NotificationsModule },
  name: 'NavLocacion',
  props: {
    currentSection: String,
  },
  data(){
    return{
      status: JSON.parse(localStorage.getItem("userData"))?.estado,
    }
  },
  methods:{

    hideAll(){
      document.getElementById("BuscadorNoSession-component-MAIN").style.display = "none";
      document.getElementById("InicioSesionCrearCuenta-component-MAIN").style.display = "none";
      document.getElementById("SubirLocacionNoSession-component-MAIN").style.display = "none";
      document.getElementById("SubirLocacionWithSession-component-MAIN").style.display = "none";
    },

    goTo(section_){
      if(section_ == "perfil"){
        if(localStorage.getItem('onSession') == null){
          section_ = "crear-cuenta";
        }
      }

      const newURL = window.location.origin + window.location.pathname;
      history.replaceState({}, document.title, newURL);
      window.location.hash = "/"+section_;
      if (localStorage.getItem('onSession') === 'true') {
        let userData = JSON.parse(localStorage.getItem('userData'));
        //console.log(userData)
        setTimeout(() => {
          document.querySelectorAll('.navProfile').forEach(element => element.style.display = "flex");
          document.querySelectorAll('.navAccount').forEach(element => element.style.display = "none");
          document.querySelectorAll('.nav-username').forEach(username => username.textContent = userData.user.S_Nombre);
          document.querySelectorAll('.nav-userpic').forEach(userpic => userpic.src =userData.user.S_URLFotoPerfil);
          console.log("cambiado")
        }, 10);
      }
      setTimeout(() => { window.scrollTo(0, 0); }, 10);
    },

  },

  mounted(){
    if (localStorage.getItem('onSession') === 'true') {
        let userData = JSON.parse(localStorage.getItem('userData'));
        //console.log(userData)
        setTimeout(() => {
          document.querySelectorAll('.navProfile').forEach(element => element.style.display = "flex");
          document.querySelectorAll('.navAccount').forEach(element => element.style.display = "none");
          document.querySelectorAll('.nav-username').forEach(username => username.textContent = userData.user.S_Nombre);
          document.querySelectorAll('.nav-userpic').forEach(userpic => userpic.src =userData.user.S_URLFotoPerfil);
          console.log("cambiado")
        }, 10);
      }
  }
}
</script>
  
<style scoped>

  nav > div{
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 8ch;
    position: fixed;
    font-size: 1.3ch;
    left: 0;
  }
  
  .nav-menu{
    z-index:3;
    background-color: rgba(255,255,255,0.9);
  }

  nav > div > div{
    margin: 0 7.5vw;
    display: flex;
  }

  nav > div > div > div{
    margin: 0 2ch;
  }

  .nav-space{
    position: relative;
  }

  .nav-logo{
    height: 3.5ch;
  }

  .nav-logo:hover{
    cursor: pointer;
  }

  .nav-links > div{
    display: flex;
    display: flex;
    align-items: center;
    font-size: 1.3ch;
  }

  .nav-links > div:not(.nav-notifications-btn):hover{
    cursor: pointer;
  }

  .nav-links > div img{
    max-width: 2.5ch;
    max-height: 2.5ch;
    margin: 0 1ch;
  }

  .nav-links > div:nth-child(4) img, .nav-links > div:nth-child(5) img{
    max-width: unset;
    max-height: unset;
    width: 5.2ch;
    height: 5.2ch;
  }

  .nav-links > div:not(.account) img{
    filter: brightness(0);
    opacity: 0.7;
  }

  .nav-active{
    font-weight: bolder;
  }

  .nav-active > img{
    filter: unset !important;
    opacity: 1;
  }

  .mobile-nav{
    display: none;
  }

  .nav-fixed{
    display: none;
  }

  .navProfile{
    display: none;
  }

  .nav-userpic{
    border-radius: 100vw;
    transform: scale(0.75);
    margin-right: -2ch !important;
  }

  .nav-links > div:nth-child(3), .nav-fixed > div:nth-child(3){
    color: #FF0A46;
    color: white;
    background: linear-gradient(#FF4C0B, #FF0A46);
    border: 1px solid red;
    padding-right: 0.5ch;
    padding-left: 1ch;
    padding-top: 0.5ch;
    padding-bottom: 0.5ch;
    border-radius: 100vw;
  }

  .nav-links > div:nth-child(3){
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding-right: 1ch;
  }

  .nav-links > div:nth-child(3) p{
    margin: 0;
    width: fit-content !important;
    min-width: unset !important;
  }

  .nav-links > div:nth-child(3) img{
  }

  .nav-links > .nav-active:nth-child(3) > img,
  .nav-fixed > .nav-active:nth-child(3) > img{
    filter: brightness(0) invert(1) !important;
  }

  .nav-fixed > div:nth-child(3){
    padding: 0.5ch 1ch;
    margin-left: -1ch;
  }

  /********************************************************************
  * RESPONSIVE
  ********************************************************************/
  @media all and (max-width:780px){

    .userpic-cel{
      align-items: center;
      justify-content: center;
      position: relative;
      font-size: 2ch;
      margin-right: -1ch;
    }

    .userpic-cel > img{
      position: relative;
      width: 6ch;
      height: 6ch;
      aspect-ratio: 1 / 1;
    }

    .mi-perfil-cel{
      width: 8vw;
      height: 8vw;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .nav-links{
      display: none;
    }

    .mobile-nav{
      display: flex;
    }

    .mobile-nav > img{
      width: 6ch;
      height: 6ch;
      cursor: pointer;
    }

    .nav-logo{
      height: 2.8ch;
    }

    nav > div > div{
      margin: 0 3vw;
    }

    button{
      background: linear-gradient(#FF4C0B, #FF0A46);
      color: white;
      padding: 2vw 4vw;
      font-size: 1.5ch;
      border: none;
      border-radius: 100vw;
      text-transform: uppercase;
      font-weight: bold;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
    }

    button > img{
      width: 1.5ch;
      margin-right: 1ch;
      filter: brightness(0) invert(1);
    }

    .nav-fixed{
      display: flex;
      position: fixed;
      bottom: 0;
      background-color: rgba(250,250,250,0.9);
      z-index: 3;
    }

    .nav-fixed > div{
      display: flex;
      width: 33.33%;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .nav-fixed > div img{
      filter: brightness(0);
      opacity: 0.7;
    }

    .nav-fixed > div:nth-child(2) img{
      margin:  0 !important;
    }

    .nav-active{
      font-weight: bolder;
    }

    .nav-active > img{
      filter: unset !important;
      opacity: 1;
    }

  }
</style>