<template>
    <section class="ChatSectionProfile-MAIN">
        <div class="chat-side-L">
            <div role="search" class="chat-search" @click="manageSearch()">
                <img src="../assets/images/icons/search.svg"><input type="text" v-on:keyup.enter="manageSearch()" v-model="searchBar">
            </div>
            <div class="chat-list">
                <div v-for="(item, index) in chatList.finalList" :key="index" class="chat-listed" @click="loadChatByClick(item.id)">
                    <img :src="item.pfp"><p>{{ item.nombre }} {{ item.apellidos }}</p>
                </div>
            </div>
        </div>
        <ChatWindow :windowMode="'full'" :receiverId="currentInterlocutorId" :transmitterId="userData.id" v-if="currentInterlocutorId != 0" :key="keyUpdater"/>
    </section>
</template>

<script>
import ChatWindow from './ChatWindow.vue';

export default {
    name: 'ChatSectionProfile',
    components: {ChatWindow},
    data(){
        return{
            searchBar: "",
            userData: JSON.parse(localStorage.getItem("userData"))?.user,
            currentInterlocutorId: 0, //160 for test
            falseTokenAdmin: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5OWM5OTE0Ny1jOTFkLTQyYTUtYjBmZi0yOGRmZmJlMGRkMDgiLCJqdGkiOiI5MmViMzJhOTNiN2Q1MWZjNDQ2NjFmMzA5ZjYzZDkzNmFlYWFjZmI0N2Y2N2JiOTFjNjcyNWRlZTA2ZDA4YTgwMzlhMmYwOTQ2YTNlYzhlMCIsImlhdCI6MTcxNjU5Mzc2Ny4xNTA0MzYsIm5iZiI6MTcxNjU5Mzc2Ny4xNTA0NDEsImV4cCI6MTc0ODEyOTc2Ny4xNDczMDgsInN1YiI6Ijc4Iiwic2NvcGVzIjpbIkNSRUFURSIsIlJFQUQiLCJVUERBVEUiLCJERUxFVEUiXX0.xpH2Y_oOTMG0KZkEKONslQMFdbGxeLoJFLBr9BVHSwuYmLVrKe861x74vfe0ALNWLQKe0HcWXM93IXOzbUSm_lmMrx2PgnhjMGjUTWG7KfGfzAHudpIwOA4ObJ4Rj4qGb5gPxeSTZ8w-458MUQtKE9J5wQ03Or8jWLH-1AMi0TgVNMv9l9t_LgM2n2i_6qHdX_Lg8m470z7jQbNuVU9VCcKDpDG0vHPTWPfGOVLdAxdjzqJ1mFTzgzpvo505Jdu7f66F42EnJIrYe8-tzLu7sZKJ4Lg0_SdAhoNq-G_nmyditsC7zFHYjqTqyunvo708cG5Hyn-kDxczfhq58a5o96_PneR38Ehsa84TSZO3Ylqye6l6125Sf-MvJ8p5SvCRtkfCjgA6wMMyyIFEefuZHBTbQVPF56SENkVyAkK9SwZAdsBTuRkcDGn6HtxkoCcC--Md-AbTvPTFjE5vXZQ4RFAganUzIjQ3mntMYABPpZTY-XDJspHfamCvLDslBnpMK62nQbJPQ-EFIGVP3_aUoiDtIfiaPsYAOrBAt1ZIKY_ouxw_U6-4LPPMyUk859ReFjAv08mnASIHS12L-r6sY1oUw-V0IbkKZO1GCoALLYPB7UiWn0AChdwtusyVruyisST0USTxgzM0823Ls0Y7ePWeLei6hOArla5dBFFN-wA",
            chatList: {
                byNotifications: [],
                idList: [],
                finalList: [],
            },
            keyUpdater: 0,
        }
    },
    methods:{
        manageSearch(){
            console.log("search", this.searchBar);
        },

        getNotifications(){
            //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://dev-locacionbackoffice.ideeo.mx/api/mis-notifaciones?usuario_id='+this.userData.id, {method: 'GET',
                headers: {
                    "Content-type": "application/json",
                    //"Authorization": this.falseToken //`Bearer ${accessToken}`
                }})
                .then(response => response.json())
                .then(json => {
                    for(let i of json){
                        this.chatList.byNotifications.push(JSON.parse(i.contenido));
                    }
                    for(let i of this.chatList.byNotifications){
                        if(i.type == "chatMsg"){
                            console.log("NOTIF filter", i)
                            if (!this.chatList.idList.includes(i.senderId)) {
                                this.chatList.idList.push(i.senderId);
                            }
                        }
                    }
                })
        },

        getDataUser(){
            //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://dev-locacionbackoffice.ideeo.mx/api/usuarios', {method: 'GET',
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${this.falseTokenAdmin}`
                }})
                .then(response => response.json())
                .then(json => {
                    this.chatList.finalList = [];
                    let arr = json.dato;
                    for(let i of arr){
                        if(this.chatList.idList.includes(i.id)){
                            //console.log(i.id, i.S_Nombre, i.S_Apellidos, i.S_URLFotoPerfil)
                            this.chatList.finalList.push({
                                id: i.id,
                                nombre: i.S_Nombre,
                                apellidos: i.S_Apellidos,
                                pfp: i.S_URLFotoPerfil,
                            })
                        }
                    }
                })
        },

        loadChatByClick(id_){
            this.currentInterlocutorId = id_;
            setTimeout(() => {
                this.keyUpdater++;
            }, 500);
        }
    },
    mounted(){
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if(id !== null){
            this.currentInterlocutorId = parseInt(id.replace("/", ""));
        }
        this.getNotifications();
        this.getDataUser();
    }
}
</script>

<style scoped>
.ChatSectionProfile-MAIN{
    display: flex;
    width: calc(100% + 5ch);
    height: calc(100% - 1ch);
    margin-right: -5ch;
    padding-top: 1ch;
    max-height: 70vh;
}

.ChatSectionProfile-MAIN > div{
    width: 30ch;
}

.chat-search{
    background-color: #E6ECF0;
    border-radius: 100vw;
    width: 95%;
    height: 2ch;
    display: flex;
    align-items: center;
}

.chat-search input{
    border: none;
    border-radius: 100vw;
    padding: 0 1ch;
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.chat-search img{
    width: 1.1ch;
    margin-left: 0.5ch;
    filter: invert(1);
    opacity: 0.2;
    cursor: pointer;
}

.chat-list{
    display: flex;
    flex-direction: column;
    margin-top: 0.5ch;
}

.chat-listed{
    display: flex;
    align-items: center;
}

.chat-listed:hover{
    background-color: #f4f4f4;
    cursor: pointer;
}

.chat-listed img{
    width: 3ch;
    aspect-ratio: 1/1;
    border-radius: 100vw;
    object-position: center;
    object-fit: cover;
    margin: 0 1ch;
}
</style>