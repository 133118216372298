<template>
    <section :class="`ChatWindow-MAIN ChatWindow-${windowMode}`">
        <div class="messages" ref="messagesContainer">
            <div v-for="(message, index) in messages" :key="index" :class="`message-item msg-${message.emisor_id == userData.id ? 'right' : 'left'}`">
                <img :src="
                        canLoadLocationData ? locationData?.Propietario?.S_URLFotoPerfil : message.emisor_id == userData.id ? usersDataByMsg.ownerPic : usersDataByMsg.clientPic
                    " v-if="renderContent(message.mensaje)?.type != 'locationOwner'">
                <div v-if="renderContent(message.mensaje)?.type != 'locationOwner'">
                    <div v-if="renderContent(message.mensaje)?.type == 'plainText'">
                        {{ renderContent(message.mensaje).txt }}
                    </div>
                    <div v-if="renderContent(message.mensaje)?.type == 'priceProject'" class="msg-priceProject">
                        <img src="../assets/images/icons/price.svg"> Precio del proyecto<br>Desde ${{ renderContent(message.mensaje)?.min }} hasta ${{ renderContent(message.mensaje)?.max }}
                    </div>
                    <div v-if="renderContent(message.mensaje)?.type == 'priceProject'" class="msg-priceProject-legend">
                        (Ese rango de costos considere que puede variar debido a la decisión final que pueda tener el locatario y no depende de la plataforma, debido a que son negociaciones que solo corresponden a las dos partes).
                    </div>
                    <div v-if="renderContent(message.mensaje)?.type == 'schedule'" class="msg-schedule">
                        <p><img src="../assets/images/icons/price.svg">&nbsp;¿Quieres agendar visita?</p>
                        <div><button @click="schedulePanelMode = 'set'">SI</button><button>NO</button></div>
                    </div>
                    <div class="msg-date">{{ message.hora_envio }}</div>
                </div>
            </div>

            <div class="chat-price-popup" v-if="pricePopUp">
                <img src="../assets/images/icons/close-price.svg" class="price-close" @click="pricePopUp = false">
                <p><img src="../assets/images/icons/price.svg"> Precio estimado de este proyecto:</p>
                <p>Puedes darle rango de costo a este proyecto.</p>
                <div>
                    <div>Desde <input type="number" v-model="inputs.priceFrom" min="8000" :max="inputs.priceTo"></div>
                    <div>Hasta <input type="number" v-model="inputs.priceTo" :min="inputs.priceFrom" max="15000"></div>
                </div>
                <img src="../assets/images/btn-sendPrice.png" @click="sendInfo('sendPrice')">
            </div>
        </div>

        <div class="msg-inputs">
            <input type="text" v-model="inputTxt">
            <img src="../assets/images/icons/send.png" @click="postMessage()">
            <div v-if="userData.id == (canLoadLocationData ? locationData?.Propietario?.id : usersDataByMsg.ownerId)">
                <img src="../assets/images/icons/priceProject.png" @click="pricePopUp = true">
                <img src="../assets/images/icons/schedule.png" @click="sendInfo('sendSchedule')">
            </div>
        </div>
        
        <section class="chat-schedule-panel" v-if="schedulePanelMode != false">
            <div v-if="schedulePanelMode == 'set'">
                <img src="../assets/images/close.png" class="close-schedule-panel" @click="schedulePanelMode = false">
                <h2>Quiero visita</h2>
                <p>Puedes utilizar esta agenda como herramienta de apoyo de tu organización y recordatorio también para el locatario. <br>La plataforma Locación no se hace responsable si hay cambio de día u horario del encuentro entre tú y el locatario.</p>
                <div>
                    <div>
                        Fecha
                        <input type="date" ref="inputDate">
                    </div>
                    <div>
                        Hora
                        <input type="time" ref="inputTime">
                    </div>
                </div>
                <label><input type="checkbox" v-model="scheduleConditionsAccepted"> Acepto términos y condiciones</label>
                <p class="min">Al continuar, aceptas las Condiciones de uso y la Política de privacidad de Locación.</p>
                <img src="../assets/images/btn-enviar-solicitud.png" :class="`btn-panel ${scheduleConditionsAccepted ? '' : 'not-accepted'}`" @click="requestNewDate()">
            </div>
            <div v-if="schedulePanelMode == 'sent'" class="schedule-panel-sent">
                <img src="../assets/images/close.png" class="close-schedule-panel" @click="schedulePanelMode = false">
                <img :src="userData.S_URLFotoPerfil" class="panel-icon-user">
                <h2>¡Suerte!</h2>
                <p>
                    <b>Solicitud enviada</b> <br>Espera la confirmación del locatario a la visita que solicitas.
                </p>
                <img src="../assets/images/btn-cerrar.png" class="btn-panel" @click="schedulePanelMode = false">
            </div>
        </section>
    </section>
</template>

<script>
export default {
    name: 'ChatWindow',
    props: {
        windowMode: String, // "micro" | "full"
        locationData: Object,
        transmitterId: Number,
        receiverId: Number,
    },
    data(){
        return{
            messages: [],
            inputTxt: "",
            cycleRequestInterval: undefined,
            userData: JSON.parse(localStorage.getItem("userData"))?.user,
            usersDataByMsg: {},
            sentFirstMsgDataUsers: false,
            canLoadLocationData: true,
            inputs:{
                priceFrom: 8000,
                priceTo: 15000,
            },
            pricePopUp: false,
            schedulePanelMode: false, // "set" | "sent" | false
            scheduleConditionsAccepted: false,
            falseToken: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5OWM5OTE0Ny1jOTFkLTQyYTUtYjBmZi0yOGRmZmJlMGRkMDgiLCJqdGkiOiJjMjNhZWM4ODlkN2ZiYTIxY2EyOTI0NjFmN2ZmZDUzN2Y3MjdlYmU4M2I2N2IwNTdlNzNkZTAwNTc0YzE5YzVhYzdkYmIwYmY0YjU4ZGZjZSIsImlhdCI6MTcyMzI0NzUwMS4wMjI5MzMsIm5iZiI6MTcyMzI0NzUwMS4wMjI5MzYsImV4cCI6MTc1NDc4MzUwMS4wMTc3NjYsInN1YiI6IjcyIiwic2NvcGVzIjpbIkNSRUFURSIsIlJFQUQiLCJVUERBVEUiLCJERUxFVEUiXX0.a356A74j7nQrUqMRY0CO7WyYgRBV1nsJdwpkk3TvI-z3ZhYFxi1lUpSEb_0AxmY3789_0Vl1ASk9lgZjjbjOAxX56fxuNAfVCz0o-TJZk8iayauypGbXHW61uOOVkGZOp8hWWUqpKJV5nwZaAoXb3Z5Yi85PIEohrz_X4gOuNxHqDU0wn7XP468b4Ul74MY5SXODtrqD5HZhdkec-3gv6iloWy_evaN-FQDvPS8SUeXAXUOMHMF6Drht9K8cCi05xGURjD03q0CepJlAS5O8IJYp3P84i5B9M_kAQJEUDDjiQciw7HYZBvcu1Rtb1T0svbTyZy8CLr60GtVoyOrrgCXOD-5CIFLkITi4mtxAaJGPpDPjJTcNYLzmgJBHlNMO1g2MrJuoNaNeE_nmUXM1Cvn0xZHOVr1W8lpLdVeFmkiKwo1bd8WPZI_a_dsZwcves_dvdQjL4hiPr2LoLp17ysuYmZCp2bvbriSc8C5b1g3ylEMoFr7NWUvF26ubR-dplvSFyo3TtBG-ohnweIhccMmvkFXlvqNRSlpq7O1cMteJhfxcD4mlktENeJxVHqvfRflAivOj6AVJVRz_erkxUDrU9vzIXv8jgJMbYfQ_qP29Vinh2HbwbjB_Q8tk2JYaUNQ6gvWiB_3Tlb27GYOcJI6cRLMyR4ZI4Mr0MHVDonQ",
        }
    },
    methods:{
        getMessages(){
            const transmitter = this.transmitterId;
            const receiver = this.receiverId;
            
            console.log(">>>>>>>>>>", transmitter, receiver, this.usersDataByMsg.clientId, this.usersDataByMsg.ownerId)

            //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://dev-locacionbackoffice.ideeo.mx/api/mensajes-chat', {method: 'POST',
                body: JSON.stringify({
                    "emisor_id": transmitter,
                    "receptor_id": receiver,
                }),
                headers: {
                    "Content-type": "application/json",
                    "Authorization": this.falseToken //`Bearer ${accessToken}`
                }})
                .then(response => response.json())
                .then(json => {
                    this.messages = json["0"];
                    setTimeout(() => {
                        try {
                            const container = this.$refs.messagesContainer;
                            container.scrollTop = container.scrollHeight;
                        } catch (error) {
                            console.log(error);
                        }
                    }, 80);
                    console.log("MSG LENGTH", this.messages.length)
                    if(this.messages.length <= 0 && this.sentFirstMsgDataUsers == false){
                        this.inputTxt = JSON.stringify({
                            type: "locationOwner",
                            ownerId: this.locationData?.Propietario?.id,
                            locationId: this.locationData?.id,
                            ownerPic: this.locationData?.Propietario?.S_URLFotoPerfil,
                            ownerName: `${this.locationData?.Propietario?.S_Nombre} ${this.locationData?.Propietario?.S_Apellidos}`,
                            clientId: this.userData.id,
                            clientPic: this.userData.S_URLFotoPerfil,
                            clientName: `${this.userData.S_Nombre} ${this.userData.S_Apellidos}`,
                        })
                        this.postMessage();
                        this.sentFirstMsgDataUsers = true;
                    }
                    this.readMsgContent();
                    this.sendInfo('clear');
                })
        },

        readMsgContent(){
            const messages = this.messages;
            for(let msg of messages){
                let result;
                try {
                    result = JSON.parse(msg.mensaje);
                } catch (error) {
                    result = msg.mensaje;
                }
                console.log(result);
                if(result.type == "locationOwner"){
                    this.usersDataByMsg = result;
                    break;
                }
            }
        },

        renderContent(content_){
            let result = "";
            try {
                result = JSON.parse(content_);
            } catch (error) {
                result = content_;
            }

            if(typeof result == "string"){
                return {type: "plainText", txt: result};
            }else{
                return result;
            }

            //Structures:
            //{"type": "plainText", "txt": "hello"}
            //{"type": "priceProject", "max": 1, "min": 99}
            //{"type": "schedule", "date": "30-12-2024", "time": "12:00"}
            //{"type": "locationOwner", "ownerId": 0, "locationId": 0, "ownerPic": ""}
        },

        getFormattedDateTime() {
            const now = new Date();

            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son 0-indexed
            const year = now.getFullYear();

            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');

            return `${day}/${month}/${year} ${hours}:${minutes}`;
        },

        postMessage(msgOrigin = "txt"){
            console.log("posting?")
            const transmitter = this.transmitterId;
            const receiver = this.receiverId;
            let msg;
            if(msgOrigin == "txt"){
                msg = this.inputTxt;
            }

            //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://dev-locacionbackoffice.ideeo.mx/api/guardar-mensaje', {method: 'POST',
                body: JSON.stringify({
                    "emisor_id": transmitter,
                    "receptor_id": receiver,
                    "mensaje": msg,
                    "visto": 0,
                    "hora_envio": this.getFormattedDateTime(),
                }),
                headers: {
                    "Content-type": "application/json",
                    "Authorization": this.falseToken //`Bearer ${accessToken}`
                }})
                .then(response => response.json())
                .then(json => {
                    if(json.msg == "Mensaje Agregado"){
                        if(msgOrigin == "txt"){
                            this.inputTxt = "";
                            if(this.userData.id != receiver){
                                this.sendChatNotification(receiver, transmitter, msg);
                            }
                        }
                        console.log("post message", receiver, transmitter, msg)
                        this.getMessages();
                    }
                })
        },

        sendInfo(mode_){
            if(mode_ == 'sendPrice'){
                const msgToSend = JSON.stringify({
                    type: "priceProject",
                    min: this.inputs.priceFrom,
                    max: this.inputs.priceTo
                });
                this.inputTxt = msgToSend;
                this.postMessage();
                return;
            }

            if(mode_ == "sendSchedule"){
                const msgToSend = JSON.stringify({"type": "schedule", "date": "", "time": ""});
                this.inputTxt = msgToSend;
                this.postMessage();
                return;
            }

            if(mode_ == "clear"){
                this.inputs = {
                    priceFrom: 8000,
                    priceTo: 15000,
                }
                this.pricePopUp = false;
                this.inputTxt = "";
                return;
            }
        },

        requestNewDate(){
            const date = this.$refs.inputDate;
            const time = this.$refs.inputTime;

            if(date.value == "" || date.value == undefined){
                date.focus();
                return;
            }
            if(time.value == "" || time.value == undefined){
                time.focus();
                return;
            }

            //console.log("request new date", date.value, time.value)

            //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://dev-locacionbackoffice.ideeo.mx/api/nueva-cita', {method: 'POST',
                body: JSON.stringify({
                    "fecha": date.value,
                    "hora": time.value,
                    "usuario_id": this.userData.id.toString(),
                    "locacion_id": this.canLoadLocationData ? this.locationData?.id : this.usersDataByMsg.locationId,
                }),
                headers: {
                    "Content-type": "application/json",
                    //"Authorization": this.falseToken //`Bearer ${accessToken}`
                }})
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    this.schedulePanelMode = 'sent';
                })
        },

        sendChatNotification(targetUserId_, myId_, lastMsg_){
            const content = JSON.stringify({type:"chatMsg", lastMsg:lastMsg_, senderId:myId_})
            
            console.log("sendChatNotification", targetUserId_, myId_);

            fetch(`https://dev-locacionbackoffice.ideeo.mx/api/nueva-notifacion?usuario_id=${targetUserId_}&contenido=${content}`, {method: 'POST',
                headers: {
                    "Content-type": "application/json",
                    //"Authorization": this.falseToken //`Bearer ${accessToken}`
                }})
                .then(response => response.json())
                .then(json => {
                    console.log("respuesta de nueva-notificacion", json);
                    if(json.error == 0){
                        this.dataSent = true;
                    }
                })
        }
    },

    mounted(){
        console.log("LOCATION DATA", this.locationData)
        if(this.locationData == undefined){
            this.canLoadLocationData = false;
        }
        
        this.getMessages();
        this.cycleRequestInterval = setInterval(()=>{
            this.getMessages();
        }, 1000*60)
    },

    beforeUnmount(){
        clearInterval(this.cycleRequestInterval);
    }
}
</script>

<style scoped>
.ChatWindow-MAIN{
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ChatWindow-micro{
    height: 37.4ch;
}

.messages{
    height: 100%;
    max-height: calc(100% - 2.5ch);
    overflow-y: auto;
    scroll-behavior: smooth;
    width: 100%;
}

.message-item{
    display: flex;
    align-items: flex-end;
    font-size: 1.2ch;
    padding: 0.5ch;
}

.message-item > img{
    width: 3ch;
    height: 3ch;
    margin-right: 1ch;
    border-radius: 100vw;
    margin-bottom:  2.9ch !important;
    border: 1px dotted lightgray;
}

.message-item > div{
    display: flex;
    flex-direction: column;
}

.msg-left > div{
    align-items: flex-start;
}

.msg-right{
    justify-content: flex-end;
}

.msg-right > div{
    align-items: flex-end;
}

.msg-right > img{
    display: none;
}

.msg-left > div > div:first-child{
    background-color: #F4F4F4;
    border-radius: 1ch 1ch 1ch 0;
}

.msg-right > div > div:first-child{
    background-color: #E6ECF0;
    border-radius: 1ch 1ch 0 1ch;
}

.message-item > div > div{
    width: fit-content;
    max-width: 75%;
    min-width: fit-content !important;
    margin-bottom: 0.5ch;
    padding: 0.5ch;
}

.msg-date{
    color: #979BA3;
    font-size: 1.1ch;
}

/* exceptions */
.msg-priceProject, .msg-schedule{
    background-color: #707070 !important;
    color: white;
    margin-bottom: 0 !important;
    line-height: 2.2ch;
    padding: 1ch !important;
}

.msg-priceProject img{
    display: inline-flex;
    width: 2ch;
    margin-bottom: -0.3ch;
}

.msg-priceProject-legend{
    font-size: 1.1ch;
    color: #979BA3;
    padding-left: 0 !important;
}

.msg-schedule p{
    margin: 0;
    display: flex;
    align-items: center;
    margin-bottom: 0.5ch;
}

.msg-schedule p img{
    display: inline-flex;
    width: 2ch;
}

.msg-schedule > div{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.msg-schedule button{
    color: white;
    background-color: #FF5107;
    border-radius: 100vw;
    border: none;
    padding: 0.2ch;
    width: 5ch;
    box-shadow: 0 0.3ch 1ch rgba(0, 0, 0, 0.2);
}

.msg-inputs{
    display: flex;
    align-items: center;
    padding: 3px 0;
    width: 100%;
}

.msg-inputs > div{
    display: flex;
    align-items: center;
    height: 100%;
}

.msg-inputs img{
    width: 2ch;
    height: 2ch;
    aspect-ratio: 1/1 !important;
    margin: 0 0.5ch;
}

.msg-inputs img:hover{
    cursor: pointer;
    scale: 1.05;
}

.msg-inputs input{
    width: 100%;
    border-radius: 100vw;
    margin-left: 0.5ch;
    border: 1px solid #FF5107;
    padding: 0.5ch 1ch;
}

.chat-price-popup{
    position: absolute;
    bottom: 0;
    border-top: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.chat-price-popup > p:nth-child(2){
    display: flex;
    align-items: center;
    width: 95%;
    font-size: 1.3ch;
    margin-bottom: 0.5ch;
}

.chat-price-popup > p:nth-child(2) img{
    filter: invert(1);
    margin-right: 0.5ch;
}

.chat-price-popup > p:nth-child(3){
    width: 95%;
    font-size: 1.1ch;
    margin-top: 0;
    color: #707070;
}
 
.chat-price-popup > img:not(.price-close){
    width: 45%;
    margin-top: 1ch;
    cursor: pointer;
}

.chat-price-popup > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 1.4ch;
}

.chat-price-popup > div > div{
    margin: 0 1ch;
}

.chat-price-popup input{
    padding: 0.5ch;
    border: 1px solid gray;
    border-radius: 1ch;
}

.price-close{
    position: absolute;
    top: 0.5ch;
    right: 0.5ch;
    width: 2ch;
    cursor: pointer;
}

/* panel */
.chat-schedule-panel{
    background-color: rgba(0, 0, 0, 0.5);
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 20;
    top: 0;
    left: 0;
    border-radius: 0;
    color: #4D4D4D;
}

.close-schedule-panel{
    position: absolute;
    width: 2ch;
    top: 2ch;
    right: 2ch;
}

.close-schedule-panel:hover{
    cursor: pointer;
    scale: 1.1;
}

.chat-schedule-panel > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 1ch;
    position: relative;
    padding: 5ch;
    width: fit-content;
    min-width: 50vw;
}

.chat-schedule-panel > div > p:nth-child(3){
    font-size: 1.4ch;
    margin-top: 0;
    text-align: center;
}

.chat-schedule-panel > div > div{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.chat-schedule-panel > div > div > div{
    min-width: 35%;
    aspect-ratio: 1/0.7;
    display: flex;
    flex-direction: column;
    margin-top: 1ch;
}

.chat-schedule-panel input{
    margin-top: 1ch;
    padding: 0.5ch
}

.chat-schedule-panel .min{
    font-size: 1.3ch;
}

.chat-schedule-panel label{
    display: flex;
    align-items: center;
}

input[type=checkbox] {
    width: 1.4ch !important;
    height: 1.4ch !important;
    background-color: white;
    border-radius: 100vw !important;
    vertical-align: middle;
    border: 1px solid #b6b6b6;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0ch;
    margin-right: 1ch;
}

input[type=checkbox]:checked {
    background-color: #FF282A;
}

.btn-panel{
    width: 20ch;
    margin-top: 1ch;
    cursor: pointer;
}

.not-accepted{
    filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none !important;
}

.schedule-panel-sent p{
    text-align: center;
    margin-top: 0;
    margin-bottom: 3ch;
}

.panel-icon-user{
    width: 6ch;
    border-radius: 100vw;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
}
</style>