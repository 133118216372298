<template>
    <div class="SubirLocacion1caracteristicas-MAIN">

        <p><img src="../assets/images/icons/house-building.svg"/>Título de tu anuncio <span class="p5-sl1-maxchar"> (Máximo 50 caracteres)</span></p>
        <input type="text" placeholder="No se permite nombre de la locación." id="upload-caracteristicasNombre" pattern="\w+" required @blur="preserveUnsavedData()" maxlength="50">

        <p><img src="../assets/images/icons/star.svg"/>Quieres rentar tu locación para:</p>
        <div class="p5-sl1-radios" id="p5-sl1-radios" tabindex="0">
            <label @click="validate('p5-sl1-radios')"><input type="checkbox" id="upload-para-que-usar-locacion-s" value="S" class="checkbox-round" @blur="preserveUnsavedData()">Shootings</label>
            <label @click="validate('p5-sl1-radios')"><input type="checkbox" id="upload-para-que-usar-locacion-f" value="F" class="checkbox-round" @blur="preserveUnsavedData()">Filmaciones</label>
            <label @click="validate('p5-sl1-radios')"><input type="checkbox" id="upload-para-que-usar-locacion-r" value="R" class="checkbox-round" @blur="preserveUnsavedData()">Reuniones</label>
            <label @click="validate('p5-sl1-radios')"><input type="checkbox" id="upload-para-que-usar-locacion-e" value="E" class="checkbox-round" @blur="preserveUnsavedData()">Eventos</label>
        </div>
        <div class="p5caracteristicas-selects">
            <div>
                <img src="../assets/images/icons/door.svg"/>
                <button id="p5-queRentas" class="btn-select"
                    @click="switchQueRentas(true), validate('p5-queRentas'), clearCheckSelected('queRentas')"
                    @blur="preserveUnsavedData()"
                    :style="`background-color:${checkIfSelected.queRentas == true ? 'lightgray' : 'white'};`"
                >¿Qué espacios rentas?</button>
            </div>
            <div>
                <img src="../assets/images/icons/flag-alt.svg"/>
                <button id="p5-QueSePuedeHacer" class="btn-select"
                    @click="switchQueSePuedeHacer(true), validate('p5-QueSePuedeHacer'), clearCheckSelected('queSePuedeHacer')"
                    @blur="preserveUnsavedData()"
                    :style="`background-color:${checkIfSelected.queSePuedeHacer == true ? 'lightgray' : 'white'};`"
                >Opciones adicionales de renta</button>
            </div>
            <div>
                <img src="../assets/images/icons/planning.svg"/>
                <button id="p5-restricciones" class="btn-select"
                    @click="switchRestricciones(true), validate('p5-restricciones'), clearCheckSelected('restricciones')"
                    @blur="preserveUnsavedData()"
                    :style="`background-color:${checkIfSelected.restricciones == true ? 'lightgray' : 'white'};`"
                >Restricciones</button>
            </div>
            <div>
                <img src="../assets/images/icons/benefits.svg"/>
                <button id="p5-beneficios" class="btn-select"
                    @click="switchBeneficios(true), validate('p5-beneficios'), clearCheckSelected('beneficios')"
                    @blur="preserveUnsavedData()"
                    :style="`background-color:${checkIfSelected.beneficios == true ? 'lightgray' : 'white'};`"
                >Beneficios</button>
            </div>
        </div>
      
        <p><img src="../assets/images/icons/speaker.svg"/>Describe tu Locación</p>
        <p class="p5-min-txt">
            * NO SE PERMITE información de nombre de personas, empresas, de locaciones, números telefónicos, links, direcciones de mails o de redes sociales, dirección de calles y número exteriores, o si son propietarios o administradores de la propiedad. Esta información será verificada antes de aprobar tu publicación.
            * No se permite colocar costos de renta de tu locación en descripción.
        </p>
        <textarea placeholder="¡Vende tu Locación! Cuenta los detalles más importantes y llamativos de tu espacio. Máximo 100 caracteres." id="upload-caracteristicasDescripcion" required @blur="preserveUnsavedData()" maxlength="1000" @input="handleTextareaInput" v-model="textareaValue"></textarea>

        <p><img src="../assets/images/icons/photo.svg"/>Fotografías</p>
        <div id="p5-inputImg">
            <img src="../assets/images/icons/clip.svg"/>
            <p>Arrastra y suelta tus archivos aquí</p>
            <p><a>O selecciona tu archivo</a></p>
            <p>Formato PNG, JPEG, JPG. Máximo 20 fotografías</p>
            <input type="file" multiple @change="handleFileSelect" id="p5-inputImg-fotos" @blur="preserveUnsavedData()">
        </div>
        <p class="photo-max-20" id="photo-max-20">¡Máximo 20 fotografías!</p>
        <p @click="resetImages(true)" class="reset-images" id="reset-images">Eliminar imágenes</p>
        
        <p class="p5-min-txt" style="flex-direction:column;">Elige las tres fotos principales que se mostrarán para posicionar tu Locación. Estas serán las primeras que verán tus clientes. Las demás que subas irán directo al álbum de la galería. No se permiten fotos de fachadas. <b style="width:100%;">Es importante que tus fotos sean de calidad para que tengas mejores resultados.</b></p>

        <div id="p5-cover-rotations" style="opacity:0.01;">{{ coverImage }}-{{ rotationImages.join("") }}</div>
        <section class="cinta-categorias">
            <img src="../assets/images/icons/arrow.svg" class="arrow-left" v-on:click="scrollImages('L')"/>
            <div class="cinta-container" id="cinta-container-images-upload">
                <div id="p5-fotos-principales">
                    <div v-for="(image, index) in selectedImages" :key="index" :id="`cover-to-select-${index}`" style="border:3px solid transparent;">
                        <!--<img :src="image" class="upload-caracteristicas-photo"/>--><!--<p>Foto destacada</p>-->
                        <div class="upload-caracteristicas-photo" @click="selectCover(index)">
                            <div :style="`background-image:url('${image}')`" :class="`upload-caracteristicas-image-container upload-caracteristicas-image-container-${index}`"></div>
                            <div class="p5-deleteImage" @click="deleteIndividualImage(index)">+</div>
                            <div class="p5-deleteImage p5-rotateImage" @click="rotateImage(index)">↻</div>
                        </div>
                    </div>
                </div>
            </div>
            <img src="../assets/images/icons/arrow.svg" class="arrow-right" v-on:click="scrollImages('R')"/>
        </section>

        <div class="p5-portada-p"><div></div> Portada </div>

        <button v-on:click="nextStep(), preserveUnsavedData()">Siguiente</button>

        <!-- Pop ups -->
        <section id="restricciones-popup">
            <div>
                <h2>Restricciones</h2>
                <p class="restricciones-antes-renta-cita" style="height:1ch; margin:0; padding:0;"></p>
                <div>
                    <label>Límite de Asistentes<input type="number" id="p5r-limiteAsistentes" class="check-invalid" required></label>
                    <label>Hora de llegada<input type="time" id="p5r-horaLlegada" class="check-invalid" required></label>
                    <label>Hora de salida<input type="time" id="p5r-horaSalida" class="check-invalid" required></label>
                </div>
                <p><img src="../assets/images/icons/forbidden.svg"/>Durante tu estancia no se permite:</p>
                <div>
                    <label><input type="checkbox" id="p5r-fumadores"><img src="../assets/images/icons/smoking.svg">Fumar</label>
                    <label><input type="checkbox" id="p5r-mascotas"><img src="../assets/images/icons/pawprint.svg">Mascotas</label>
                    <label><input type="checkbox" id="p5r-fiestas"><img src="../assets/images/icons/party.svg">Ruido alto</label>
                    <label><input type="checkbox" id="p5r-menores18"><img src="../assets/images/icons/no18.svg">Menores de 18</label>
                    <label><input type="checkbox" id="p5r-catering"><img src="../assets/images/icons/buffet.svg">Catering externo</label>
                    <label><input type="checkbox" id="p5r-montajes"><img src="../assets/images/icons/montajes_nocturnos.svg">Montajes nocturnos</label>

                    <label><input type="checkbox" id="p5r-valetparking"><img src="../assets/images/icons/valet_parking.svg">Valet Parking</label>

                </div>
                <p><img src="../assets/images/icons/checklist.svg"/>Reglas adicionales</p>
                <div>
                    <p>Este apartado es opcional, dependerá de las especificaciones que desees contemplar</p>
                    <div>
                    <label><input type="checkbox" id="p5r-poliza"><img src="../assets/images/icons/poliza_seguro.svg">Sí requiero Póliza de Seguro</label>
                    <label><input type="checkbox" id="p5r-permisos"><img src="../assets/images/icons/permisos_especiales.svg">Sí requiero Permisos Especiales</label>
                    </div>
                    <textarea placeholder="*Opcional: Anota los detalles de las restricciones de tu espacio. Máximo 1000 caracteres." id="p5r-adicionales" @input="handleTextareaRestriccionesInput" v-model="textareaRestriccionesValue" maxlength="1000"></textarea>
                </div>

                <button @click="getRestriccionesData(), verifySelected('restricciones')">Aceptar</button>
                <div class="restricciones-closebutton" @click="switchRestricciones(false)">+</div>
            </div>
        </section>

        <section id="queRentas-popup">
            <div>
                <h2>¿Qué es lo que rentas?</h2>  
                <p class="restricciones-antes-renta-cita">Selecciona las opciones que estás poniendo en renta.</p>
                <div class="popups-grid" id="queRentas-grid">
                    <label v-for="(item, index) in queRentas" :key="index">
                        <input type="checkbox" :value="item" name="p5-queRentas-inputs" @click="changeImgPreview(item)"><span>{{ item }}</span>
                    </label>
                </div>
                <button @click="getQueRentasData(), verifySelected('queRentas')">Aceptar</button>     
                <div class="restricciones-closebutton" @click="switchQueRentas(false)">+</div>
            </div>
        </section>

        <section id="queSePuedeHacer-popup">
            <div>
                <h2>¡Puedes rentar para más opciones!</h2> 
                <p class="restricciones-antes-renta-cita" style="text-align: center;">Si quieres ampliar tus posibilidades de negocio, puedes elegir más opciones de renta.</p>
                <div class="ninguna-option">
                    <label>
                            <input type="checkbox" value="Ninguna" name="p5-queSePuedeHacer-inputs"><span>Ninguna</span>
                    </label>
                </div>
                <div class="popups-grid" id="queSePuedeHacer-grid">
                    <label v-for="(item, index) in queSePuedeHacer" :key="index">
                        <input type="checkbox" :value="item" name="p5-queSePuedeHacer-inputs"><span>{{ item }}</span>
                    </label>
                </div>
                <button @click="getQueSePuedeHacerData(), verifySelected('queSePuedeHacer')">Aceptar</button>
                <div class="restricciones-closebutton" @click="switchQueSePuedeHacer(false)">+</div>       
            </div>
        </section>

        <section id="beneficios-popup">
            <div>
                <h2>Beneficios</h2> 
                <p class="restricciones-antes-renta-cita"></p>
                <div class="popups-grid" id="beneficios-grid">
                    <label v-for="(item, index) in beneficios" :key="index">
                        <input type="checkbox" :value="item" name="p5-beneficios-inputs"><span>{{ item }}</span>
                    </label>
                </div>
                <button @click="getBeneficiosData(), verifySelected('beneficios')">Aceptar</button>
                <div class="restricciones-closebutton" @click="switchBeneficios(false)">+</div>       
            </div>
        </section>

    </div>
</template>

<script>
import Compress from 'compress.js';
import { element, stylize } from 'alexa-reactor-core';
export default {
    name: 'SubirLocacion1caracteristicas',
    data() {
        return {
            selectedImages: [],
            presentationInterval: undefined,
            queSePuedeHacerData: {},
            textareaValue: '',
            textareaRestriccionesValue: '',
            rotationImages: [],
            coverImage: null,
            queRentas: [
                    "Aeropuerto",
                "Alberca",
                    "Auto",
                    "Avión",
                    "Avioneta",   
                "Azotea",     
                "Bar",        
                "Barbería",   
                "Biblioteca", 
                "Bodega",     
                "Bosque",     
                "Caballeriza",
                "Cabaña",     
                "Cabina de radio",
                "Cafetería",
                "Campo abierto",
                "Campo de golf", 
                    "Campo hípico",
                "Cancha Deportiva",
                "Cárcel",
                "Casa",
                "Casa Antigua",
                "Casa con alberca",
                "Casa con río",
                "Casa de lujo",
                "Casa en condominio",
                "Casa en el lago",
                "Casa en la montaña",
                "Casa en la playa",
                "Casa estilo colonial",
                "Casa estilo gótico",
                "Casa victoriana",
                "Casino",
                "Casona",
                "Casona colonial",
                "Castillo",
                "Cava",
                "Centro deportivo",
                "Cine",
                "Club de playa",
                "Club deportivo",
                "Cocina",
                "Comedor ",
                "Comedor modesto",
                "Condominio",
                "Consultorio",
                "Coworking",
                "Cuarto ",
                "Cuarto de juegos niños",
                "Departamento",
                "Departamentos de lujo",
                "Discoteca",
                "Edificio",
                "Edificio en construcción",
                "Escaleras",
                "Escuela",
                "Establo",
                "Estacionamiento",
                "Estadio",
                "Estética",
                "Estudio de grabación",
                "Estudio de tatuajes",
                "Estudio fotográfico",
                "Fábrica ",
                "Ferreteria",
                "Food truck",
                "Foro de T.V",
                "Fuente",
                "Galería",
                "Garage",
                "Gimnasio",
                "Granja",
                "Hacienda",
                    "Hangar",
                "Helipuerto",
                "Hospital",
                "Hotel",
                "Huerta",
                "Iglesia",
                "Jacuzzi",
                "Jardín",
                "Juegos infantiles",
                "Laboratorios",
                "Laguna",
                "Lobby",
                "Local",
                "Loft",
                "Ludoteca",
                "Mirador",
                "Mostrador",
                "Oficina",
                "Oficinas",
                "Palacio",
                "Palco",
                "Parque ",
                "Parque acuático",
                "Parque de diversiones",
                "Parrillas",
                "Parroquia",
                "Pasillos",
                "Patio",
                "Penthouse",
                "Pista de Hielo",
                "Playa",
                "Plaza comercial",
                "Plaza de toros",
                "Quinta",
                "Rancho",
                "Restaurante",
                "Roof Garden",
                "Sala de estar modesta",
                "Sala de juegos adultos",
                "Sala de juntas",
                "Salón de eventos infantiles",
                "Salón de fiesta",
                "Sótano",
                "Supermercado",
                "Taller ",
                "Taller mecánico",
                "Teatro",
                "Terraza",
                "Vecindad",
                "Vestidor",
                "Vestidor deportivo",
                "Viñedo",
                "Walk-in Closet",
                "Yates",
                "Yonke",
                "Zona arqueológica",
            ],
            queSePuedeHacer: [
                //"Ninguna",
                "Baby Shower",
                "Cenas Exclusivas y Catas",
                "Clases",
                "Clases de Esparcimiento",
                "Coworking",
                "Eventos Corporativos",
                "Eventos de Capacitación",
                "Eventos de marca",
                "Eventos de Paga",
                "Eventos deportivos",
                "Eventos infantiles",
                "Eventos Lúdicos",
                "Eventos sociales",
                "Exposición de Arte",
                "Fin de Semana con Amigos",
                "Fin de Semana Familiar",
                "Aniversario y Propuesta de matrimonio",
                "Retiros",
                "Reuniones",
                "Showroom",
                "Venta de Productos"
            ],
            beneficios: [
                "Más de 2 lugares de estacionamiento",
                "Más de 2 baños",
                "Espacio para camerino",
                "Incluye día anterior de montaje",
                "No restricción de horario nocturno",
                "Locación amueblada",
                "Locación no amueblada",
                "Wi-Fi"
            ],
            checkIfSelected: {
                queRentas: false,
                queSePuedeHacer: false,
                restricciones: false,
                beneficios: false
            },
            temporaryText: ""
        };
    },
    methods:{
        nextStep(){
            this.$parent.nextStep();
        },

        resetImages(button_=false){
            let descriptionElement = document.getElementById("upload-caracteristicasDescripcion");
            const desc = descriptionElement.value;
            this.selectedImages = [];
            this.rotationImages = [];
            this.$emit('images-uploaded', this.selectedImages);
            setTimeout(() => {
                if(button_){
                    descriptionElement.value = desc;
                }
                this.preserveUnsavedData();
            }, 100);
            stylize("#photo-max-20").display = "none";
            localStorage.removeItem("editImagesArray");
        },

        handleFileSelect(event) {
            let descriptionElement = document.getElementById("upload-caracteristicasDescripcion");
            const desc = descriptionElement.value;
            //console.log("Num img subidas: ", this.selectedImages.length);
            let previewImages = document.getElementById("upload-preview-images");
            previewImages.innerHTML = "";
            clearInterval(this.presentationInterval);
            let counter = 0;

            const inputFiles = event.target.files;
            if (!inputFiles || inputFiles.length === 0) {
                console.log("No se seleccionó ningún archivo.");
                return;
            }

            if (inputFiles.length > 0) {
                let promises = []; // Array para almacenar las promesas de compresión y carga de las imágenes
                console.log("Input files + selected imgs: ", inputFiles.length, this.selectedImages )

                console.log("INPUT FILES")
                console.log(inputFiles)
                console.log("END INPUT FILES")

                if(inputFiles.length + this.selectedImages.length > 20){
                    console.log("¡Máximo 20 imágenes!");
                    stylize("#photo-max-20").display = "flex";
                    return;
                }else{
                    stylize("#photo-max-20").display = "none";
                }

                for (const file of inputFiles) {
                    // Almacena las promesas en el array
                    promises.push(this.compressAndUploadImage(file));
                }

                // Espera a que todas las promesas se resuelvan
                Promise.all(promises)
                    .then(() => {
                        let images = this.selectedImages;

                        let currentRotationImages = this.rotationImages;
                        this.rotationImages = [];
                        for(let i in this.selectedImages){
                            this.rotationImages.push(currentRotationImages[i]);
                        }
                                            
                        this.$emit('images-uploaded', this.selectedImages);

                        // Ahora puedes iterar sobre el array de imágenes almacenado
                        for (let src_ of images) {
                            // Puedes hacer cualquier cosa con src_, por ejemplo, agregar las imágenes a un elemento en la página
                            let imgElement = document.createElement("img");
                            imgElement.src = src_;
                            previewImages.appendChild(imgElement);
                        }
                        setTimeout(() => {
                            this.presentationInterval = setInterval(() => {
                                previewImages.scrollLeft += previewImages.offsetWidth;
                                counter++;
                                if(counter == this.selectedImages.length){
                                    counter = 0;
                                    previewImages.scrollLeft = 0;
                                }
                            }, 3000);
                        }, 100);
                        localStorage.setItem("editImagesArray", this.selectedImages);
                        console.log("SELECTED IMAGES", this.selectedImages);
                        setTimeout(() => {
                            descriptionElement.value = desc;
                        }, 80);
                    })
                    .catch((error) => {
                        console.error("Error al comprimir o cargar las imágenes:", error);
                    });
            } else {
                console.log("No se seleccionó ningún archivo.");
            }
        },

        compressAndUploadImage(file) {
            // Comprimir y subir la imagen, y almacenarla en this.selectedImages
            const compress = new Compress();
            return compress
                .compress([file], {
                    size: 4, // Define el tamaño objetivo en MB
                    quality: 0.75, // Define la calidad de compresión
                })
                .then((data) => {
                    const compressedImage = data[0];
                    const blob = Compress.convertBase64ToFile(compressedImage.data, compressedImage.ext);

                    const formData = new FormData();
                    formData.append("lvl1", "usuarios");
                    formData.append("lvl2", "perfil");
                    formData.append("archivo", blob, file.name);

                    return fetch("https://dev-locacionbackoffice.ideeo.mx/api/sistema_de_carga", {
                        method: "POST",
                        body: formData,
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            this.selectedImages.push(data.cargada_en.replace(`/public/`, `https://dev-locacionbackoffice.ideeo.mx/`));
                            for (let i = 0; i < this.selectedImages.length; i++) this.rotationImages.push(0);
                            console.log(data.cargada_en)
                        });
                })
                .catch((error) => {
                    console.error("Error al comprimir la imagen:", error);
                });
        },

        scrollImages(direction_){
            let amountOfScroll = document.getElementById("p5-fotos-principales").offsetWidth;
            document.getElementById("p5-fotos-principales").scrollLeft += direction_ == 'L' ? -amountOfScroll : amountOfScroll;
        },

        changeImgPreview(value_) {
            console.log("Test de cambio imagen")
            // image replacer-
            let result = value_.replaceAll(" ", "").toLowerCase();
            if(result == "jardin") result = "jardines";
            if(result == "terraza") result = "terrazas";
            if(result == "casa") result = "casas";
            if(result == "bodega") result = "bodegas";
            if(result == "hospital") result = "hospitales";
            if(result == "hacienda") result = "haciendas";
            if(result == "helipuerto") result = "helipuertos";
            if(result == "local") result = "locales";
            if(result == "alberca") result = "albercas";
            if(result == "oficina") result = "oficinas";
            if(result == "cabaña" || result == "cabañas") result = "house-tree";

            if(result == "") result = "casas";

            console.log(result);

            try {
                document.getElementById("upload-preview-type").src = require(`../assets/images/icons/${result}.svg`);
            } catch (error) {
                document.getElementById("upload-preview-type").src = require(`../assets/images/icons/casas.svg`);
                console.log("No existe img");
            }
            
        },

        validate(id_){
            document.getElementById(id_).classList.remove("check-false");
            if(id_ == "p5-sl1-radios"){
                return;
            }
            document.getElementById(id_).innerHTML = "GUARDADO";
        },

        switchRestricciones(mode_){
            if(mode_){
                let descriptionElement = document.getElementById("upload-caracteristicasDescripcion");
                const desc = descriptionElement.value;
                this.temporaryText = desc;
                setTimeout(() => {
                    descriptionElement.value = desc; 
                }, 80);
            }
            event.preventDefault();
            document.getElementById("restricciones-popup").style.display = mode_ == true ? "flex" : "none";
            setTimeout(() => {
                document.getElementById("p5-restricciones").style.display = mode_ == true ? "none" : "flex";
                this.preserveUnsavedData();
            }, 40);
        },
        getRestriccionesData(){
            this.switchRestricciones(false);
            document.getElementById("p5-restricciones").classList.remove("check-false");
            setTimeout(() => {
                document.getElementById("upload-caracteristicasDescripcion").value = this.temporaryText;
                this.preserveUnsavedData();
            }, 80);
        },

        switchQueRentas(mode_){
            let descriptionElement = document.getElementById("upload-caracteristicasDescripcion");
            const desc = descriptionElement.value;
            event.preventDefault();
            document.getElementById("queRentas-popup").style.display = mode_ == true ? "flex" : "none";
            setTimeout(() => {
                document.getElementById("p5-queRentas").style.display = mode_ == true ? "none" : "flex";
                this.preserveUnsavedData();
                descriptionElement.value = desc;
            }, 40);
        },
        getQueRentasData(){
            this.switchQueRentas(false);
            document.getElementById("p5-queRentas").classList.remove("check-false");
        },

        switchQueSePuedeHacer(mode_){
            let descriptionElement = document.getElementById("upload-caracteristicasDescripcion");
            const desc = descriptionElement.value;
            event.preventDefault();
            document.getElementById("queSePuedeHacer-popup").style.display = mode_ == true ? "flex" : "none";
            setTimeout(() => {
                document.getElementById("p5-QueSePuedeHacer").style.display = mode_ == true ? "none" : "flex";
                this.preserveUnsavedData();
                descriptionElement.value = desc;
            }, 40);
        },
        getQueSePuedeHacerData(){
            this.switchQueSePuedeHacer(false);
            document.getElementById("p5-QueSePuedeHacer").classList.remove("check-false");
        },

        switchBeneficios(mode_){
            let descriptionElement = document.getElementById("upload-caracteristicasDescripcion");
            const desc = descriptionElement.value;
            event.preventDefault();
            document.getElementById("beneficios-popup").style.display = mode_ == true ? "flex" : "none";
            setTimeout(() => {
                document.getElementById("p5-beneficios").style.display = mode_ == true ? "none" : "flex";
                this.preserveUnsavedData();
                descriptionElement.value = desc;
            }, 40);
        },
        getBeneficiosData(){
            this.switchBeneficios(false);
            document.getElementById("p5-beneficios").classList.remove("check-false");
        },

        handleTextareaInput(event) {
            // Obtén el valor actual del textarea
            let inputValue;
            if(event == "textarea"){
                inputValue = document.getElementById("upload-caracteristicasDescripcion").value;
            }else{
                inputValue = event.target.value;
            }
            // Elimina caracteres no deseados
            inputValue = inputValue.replace(/\$/g, ""); // Elimina signos de pesos
            inputValue = inputValue.replace(/\d/g, (match, offset, string) => {
                // Reemplaza solo los números si ya hay 3 números
                if ((string.match(/\d/g) || []).length >= 4) {
                    return '';
                }
                return match;
            });
            // Actualiza el valor del textarea
            this.textareaValue = inputValue;
        },

        handleTextareaRestriccionesInput(event) {
            // Obtén el valor actual del textarea
            let inputValue = event.target.value;
            // Elimina caracteres no deseados
            inputValue = inputValue.replace(/\$/g, ""); // Elimina signos de pesos
            inputValue = inputValue.replace(/\d/g, (match, offset, string) => {
                // Reemplaza solo los números si ya hay 3 números
                if ((string.match(/\d/g) || []).length >= 4) {
                    return '';
                }
                return match;
            });
            // Actualiza el valor del textarea
            this.textareaRestriccionesValue = inputValue;
        },

        preserveUnsavedData(){
            this.$parent.preserveUnsavedData();
        },

        selectCover(index_){
            console.log(index_);
            for (let i in this.selectedImages) {
                element(`#cover-to-select-${i}`).style.border = "3px solid transparent";
            }
            element(`#cover-to-select-${index_}`).style.border = "3px solid #FF282A";
            this.coverImage = index_;
        },

        deleteIndividualImage(index_){
            let descriptionElement = document.getElementById("upload-caracteristicasDescripcion");
            const desc = descriptionElement.value;
            console.log("Image to delete:", index_)
            event.stopPropagation();
            let removedItem = this.selectedImages.splice(index_, 1);
            let removedRotationItem = this.rotationImages.splice(index_, 1);

            this.$parent.handleImagesUploaded(this.selectedImages);
            
            let counter = 0;
                    let previewImages = document.getElementById("upload-preview-images");
                    previewImages.innerHTML = "";
                    for (let src_ of this.selectedImages) {
                        // Puedes hacer cualquier cosa con src_, por ejemplo, agregar las imágenes a un elemento en la página
                        let imgElement = document.createElement("img");
                        imgElement.src = src_;
                        previewImages.appendChild(imgElement);
                    }
                    setTimeout(() => {
                        this.presentationInterval = setInterval(() => {
                            previewImages.scrollLeft += previewImages.offsetWidth;
                            counter++;
                            if(counter == this.selectedImages.length){
                                counter = 0;
                                previewImages.scrollLeft = 0;
                            }
                        }, 3000);
                    }, 100);

            console.log(removedItem, removedRotationItem);
            setTimeout(() => {
                descriptionElement.value = desc;
                this.preserveUnsavedData();
            }, 100);
        },

        rotateImage(index_){
            let descriptionElement = document.getElementById("upload-caracteristicasDescripcion");
            const desc = descriptionElement.value;
            event.stopPropagation();
            console.log("Preparing for rotation:");
 
            if(this.rotationImages[index_] == 3)
                this.rotationImages[index_] = 0;
            else
                this.rotationImages[index_] += 1;

            stylize(`.upload-caracteristicas-image-container-${index_}`).transform = `rotate(${90*this.rotationImages[index_]}deg)`;
            setTimeout(() => {
                descriptionElement.value = desc;
            }, 80);
        },

        verifySelected(select_){
            //names: p5-queRentas-inputs, p5-queSePuedeHacer-inputs, p5-beneficios-inputs
            if(select_ != "restricciones"){
                const allInputs = Array.from(document.getElementsByName(`p5-${select_}-inputs`));
                for(let i of allInputs){
                    console.log(i.checked);
                    if(i.checked == true){
                        if(select_ == "queRentas")
                            this.checkIfSelected.queRentas = true;
                        if(select_ == "queSePuedeHacer")
                            this.checkIfSelected.queSePuedeHacer = true;
                        if(select_ == "beneficios")
                            this.checkIfSelected.beneficios = true;
                        break;
                    }
                }
            }else{
                try {
                    let limiteAsistentes = document.getElementById('p5r-limiteAsistentes').value;
                    let horaLlegada = document.getElementById("p5r-horaLlegada").value;
                    let horaSalida = document.getElementById("p5r-horaSalida").value;
                    if(limiteAsistentes == "" || horaLlegada == "" || horaSalida == ""){
                        console.log("falta llenar restriccion")
                    }else{
                        this.checkIfSelected.restricciones = true;
                    }
                } catch (error) {
                    console.log("error");
                }
            }

            console.log(this.checkIfSelected);
        },

        clearCheckSelected(select_){
            if(select_ == "queRentas")
                return this.checkIfSelected.queRentas = false;
            if(select_ == "queSePuedeHacer")
                return this.checkIfSelected.queSePuedeHacer = false;
            if(select_ == "restricciones")
                return this.checkIfSelected.restricciones = false;
            if(select_ == "beneficios")
                return this.checkIfSelected.beneficios = false;
        }

    },

    mounted(){
        const queryString = window.location.search;
        let result = new URLSearchParams(queryString);
        result = parseInt(result.get("edit"));
        if(!localStorage.getItem("editImagesArray")){this.resetImages();}
    
        if(isNaN(result)){
            console.log(0);
            if(!localStorage.getItem("editImagesArray")){this.resetImages();}
            setTimeout(() => {
                
                let preservedData = localStorage.getItem('preserveUnsavedData');
                preservedData = JSON.parse(preservedData);
                if(preservedData != null)
                    try {
                        let retrieveSelectedImages = JSON.parse(localStorage.getItem("editImagesArray"));
                        retrieveSelectedImages = [...retrieveSelectedImages];
                        this.selectedImages = retrieveSelectedImages;
                        for (let i = 0; i < this.selectedImages.length; i++) this.rotationImages.push(0);

                        let portadaFromPreserved = parseInt(preservedData.portada_rotaciones.split("-")[0]);
                        let rotationsFromPreserved = preservedData.portada_rotaciones.split("-")[1];
                        try {
                            if(!isNaN(portadaFromPreserved)){
                                console.log("333")
                                this.coverImage = portadaFromPreserved;
                                
                                setTimeout(() => {
                                    try {
                                        this.selectCover(portadaFromPreserved);
                                    } catch (error) {
                                        this.coverImage = null;
                                    } 
                                }, 800);
                            }
                            if(rotationsFromPreserved.length > 0){
                                rotationsFromPreserved = rotationsFromPreserved.split("");
                                this.rotationImages = [];
                                for(let x in rotationsFromPreserved){
                                    this.rotationImages.push(parseInt(rotationsFromPreserved[x]));
                                    setTimeout(() => {
                                        stylize(`.upload-caracteristicas-image-container-${x}`).transform = `rotate(${90*parseInt(rotationsFromPreserved[x])}deg)`;
                                        //console.log(">>>", 90*parseInt(rotationsFromPreserved[x]))
                                    }, 500);
                                }
                            }
                        } catch (error) {
                            console.log(error);
                        }

                        setTimeout(() => {
                            this.textareaValue = preservedData.S_Description;
                            this.handleTextareaInput("textarea");
                            this.textareaRestriccionesValue = preservedData.adicionales;
                        }, 80);
                        
                    } catch (error) {
                        console.log(error);
                        if(!localStorage.getItem("editImagesArray")){this.resetImages();}
                    }
            }, 2000);
        }else{
            setTimeout(() => {
                try {
                    this.selectedImages = eval(localStorage.getItem("editImagesArray"));
                    for (let i = 0; i < this.selectedImages.length; i++) this.rotationImages.push(0);
                } catch (error) {
                    console.log(error);
                    if(!localStorage.getItem("editImagesArray")){this.resetImages();}
                }
            }, 2000);    
        }

        setTimeout(() => {
            this.verifySelected("queRentas");
            this.verifySelected("queSePuedeHacer");
            this.verifySelected("restricciones");
            this.verifySelected("beneficios");
        }, 1800);
    }
}
</script>

<style scoped>
.SubirLocacion1caracteristicas-MAIN{
    display: flex;
    flex-direction: column;
    max-width: 55vw;
}

.p5-sl1-maxchar{
    font-size: 1.2ch;
    margin-left: 1ch;
    margin-top: 0.3ch;
    color: gray;
}

.p5-sl1-radios{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1ch;
    padding: 0.5ch 0.5ch;
}

.p5caracteristicas-selects{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 1.5ch;
    margin-top: 4ch;
}

.p5caracteristicas-selects > div{
    display: flex;
    align-items: center;
    width: 90%;
}

.p5caracteristicas-selects > div img{
    width: 3ch !important;
    min-width: 3ch !important;
    max-width: unset !important;
    margin-right: 1ch;
}

#p5-inputImg{
    border: 1px dashed gray;
    border-radius: 1ch;
    background-color: rgb(243, 243, 243);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2ch 0;
    position: relative;
}

#p5-inputImg > p{
    color: gray;
    width:80%;
    text-align: center;
    font-size: 1.4ch;
    margin: 0.5ch;
}

#p5-inputImg > p a{
    color:blue;
    text-decoration: underline;
}

#p5-inputImg > input{
    position: absolute;
    border: 1px solid red;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

textarea{
    height: 16ch;
    resize: none;
    margin-top: 1ch;
}

.cinta-categorias{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 10vw;
}

.cinta-container > div, .cinta-container{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.cinta-container > div{
    width: 90%;
    overflow: hidden;
    justify-content: flex-start;
    margin-top: 1vw;
    scroll-behavior: smooth;
}

.cinta-container > div > div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SubirLocacion1caracteristicas-MAIN .cinta-container > div > div{
    width: 25% !important;
    height: 17vw;
    min-width: 25%;
    margin: 0 2%;
    display: flex;
    border-radius: 1vw;
    overflow: hidden;
}

.SubirLocacion1caracteristicas-MAIN .cinta-container > div img{
    max-width: 120%;
    max-height: 120%;
    min-height: 100%;
    min-width: 100%;
}

.SubirLocacion1caracteristicas-MAIN .arrow-left, .SubirLocacion1caracteristicas-MAIN .arrow-right{
    width: 1.5ch;
    cursor: pointer;
}

.SubirLocacion1caracteristicas-MAIN .arrow-right{
    transform: rotate(180deg);
}

.check-invalid:invalid, .check-false{
    border: 1px solid red !important;
    outline: 1px solid red !important;
    background-color: rgb(255, 241, 241);
}

.upload-caracteristicas-photo{
    height: 100%;
    width: 20vw;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.upload-caracteristicas-image-container{
    position: absolute;
    background-size: inherit;
    background-position: inherit;
    height: 100%;
    aspect-ratio: 1 / 1;
}

.p5-deleteImage{
    width: 1.5ch;
    height: 1.5ch;
    font-size: 2ch;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    position: absolute;
    top: 5px;
    right: 3.6vw;
    z-index: 2;
    transform: rotate(45deg);
    border-radius: 100vw;
}

.p5-deleteImage:hover{
    cursor: pointer;
    transform: rotate(45deg) scale(1.1);
    transition: all 0.1s;
}

.p5-rotateImage{
    transform: none;
    right: unset;
    left: 4vw;
}

.p5-rotateImage:hover{
    transform: scale(1.1);
}

input[type=checkbox] {
    width: 10px !important;
    height: 10px !important;
    background-color: white;
    border-radius: 100vw !important;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    transform: scale(0.6);
}

input[type=checkbox]:checked {
    background-color: #FF282A;
}

/* restricciones */
#restricciones-popup, #queRentas-popup, #queSePuedeHacer-popup, #beneficios-popup{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
    display: none;
    border-radius: 0;
    overflow: auto;
}

#restricciones-popup > div, #queRentas-popup > div, #queSePuedeHacer-popup > div, #beneficios-popup > div{
    background-color: white;
    border-radius: 2ch;
    padding: 1ch 3ch;
    display: flex;
    flex-direction: column;
    width: 64ch;
    max-height: 96vh !important;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.restricciones-closebutton{
    position: absolute;
    top: 0.5ch;
    right: 0.5ch;
    transform: rotate(45deg);
    font-size: 5ch;
    width: 1ch;
    height: 1ch;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: gray;
    font-weight: 300;
    cursor: pointer;
}

#restricciones-popup label{
    display: flex;
}

#restricciones-popup label img{
    margin-top: 0.1ch;
}

#restricciones-popup > div h2,
#queRentas-popup > div h2,
#queSePuedeHacer-popup > div h2,
#beneficios-popup > div h2{
    margin-bottom: 0;
    align-self: center;
}

#restricciones-popup > div p:nth-child(2),
#queRentas-popup > div p:nth-child(2),
#queSePuedeHacer-popup > div p:nth-child(2),
#beneficios-popup > div p:nth-child(2){
    margin-top: 0.5ch;
    margin-bottom: 3.5ch;
    align-self: center;
    color: gray;
}

#restricciones-popup > div > div:nth-child(3){
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#restricciones-popup > div > div:nth-child(3) label{
    width: 28%;
    display: flex;
    flex-direction: column;
}

#restricciones-popup > div > div:nth-child(3) label input{
    margin-top: 1ch;
}

#restricciones-popup > div > div:nth-child(5){
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: 33.3% 33.3% 33.3%;
    justify-content: space-between;
    margin-bottom: -2ch;
}

#restricciones-popup > div > div:nth-child(5) label{
    margin-bottom: 1ch;
    margin-top: 0.5ch;
}

#restricciones-popup > div > div:nth-child(7) div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1ch 0;
    margin-left: 1ch;
}

#restricciones-popup > div > div:nth-child(7) p{
    font-size: 1ch;
    margin-left: 2ch;
}

#restricciones-popup > div > div:nth-child(7) textarea{
    width: 96%;
    height: 10ch;
}

#restricciones-popup > div >p{
    margin-top: 3ch;
}

#restricciones-popup img{
    max-height: 2ch;
    max-width: 2.5ch;
    margin:0.5ch;
    margin-right: 1ch;
    margin-bottom: -0.5ch;
}

.popups-grid{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 50vh;
}

.popups-grid > label{
    height: 5ch;
    margin-bottom: 1ch;
    display: flex;
}

.reset-images{
    font-size: 1.5ch;
    text-decoration: underline;
    color:#767676;
    width: fit-content;
}

.reset-images:hover{
    cursor: pointer;
    color: black;
}

.p5-portada-p{
    font-size: 1.5ch;
    display: flex;
    align-items: center;
}

.p5-portada-p > div{
    background-color: #FF282A;
    display: flex;
    border-radius: 100vw;
    height: 1ch;
    width: 1ch;
    margin-right: 1ch;
}

.photo-max-20{
    color: red;
    margin: 0;
    margin-top: 1.5ch;
    display: none;
}

.btn-select{
    padding: 1ch 1ch;
    background: unset;
    font-weight: unset;
    color: black;
    text-align: left;
    border: 1px solid rgb(70, 70, 70);
    margin-top: 1.5ch;
    border-radius: 5px;
    text-transform: unset;
}

.ninguna-option{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5ch;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    .p5-sl1-radios{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 1.5ch;
    }

    .p5caracteristicas-selects{
        display: flex;
        flex-direction: column;
        margin-top: 12vw;
    }

    .p5caracteristicas-selects > div{
        width: 98%;
    }

    .p5caracteristicas-selects > div img{
        max-width: 6vw;
        margin-right: 1ch;
    }

    #p5-inputImg{
        border: none;
        background-color: transparent;
        padding: 0;
        font-size: 4vw;
        text-align: left !important;
    }

    #p5-inputImg > p:not(:nth-child(4)), #p5-inputImg img{
        display: none;
    }

    #p5-inputImg > p:nth-child(4)::before{
        content: "+";
        position: absolute;
        left: 35vw;
        font-size: 8vw;
        margin-top: -13vw;
        color: orangered;
    }

    #p5-inputImg p{
        text-align: left;
        width: 100%;
    }

    #p5-inputImg > input{
        display: flex;
        margin-top: -10vw;
        padding-top: 10vw;
    }

    .SubirLocacion1caracteristicas-MAIN .arrow-left, .SubirLocacion1caracteristicas-MAIN .arrow-right{
        display: none;
    }

    .cinta-container{
        margin-top: 2vw;
        margin-bottom: 54vw;
    }

    .cinta-container > div {
        width: 90vw;
        position: absolute;
        overflow: auto;
    }
    
    .SubirLocacion1caracteristicas-MAIN{
        max-width: unset;
    }

    .SubirLocacion1caracteristicas-MAIN .cinta-container > div > div{
        height: 60vw;
        width: 52vw !important;
        min-width: 52vw !important;
    }


    .upload-caracteristicas-photo{
        height: 100%;
        width: 60vw;
        border-radius: 2vw;
    }


    /* restricciones */
    #restricciones-popup > div, #queRentas-popup > div, #queSePuedeHacer-popup > div,  #beneficios-popup > div{  
        position: absolute;
        top: 0;
        left: 0;
        min-width: unset;
        width: 100%;
        overflow: hidden;
        padding: 0;
        margin: 0;
        border-radius: 0;
    }

    #queRentas-popup > div, #queSePuedeHacer-popup > div{
        height: 100%;
    }

    #restricciones-popup > div{
        overflow-y: auto;
    }

    #restricciones-popup > div > *:not(.restricciones-closebutton),
    #queRentas-popup > div > *:not(.restricciones-closebutton),
    #queSePuedeHacer-popup > div > *:not(.restricciones-closebutton),
    #beneficios-popup > div > *:not(.restricciones-closebutton){
        width: 85vw;
        align-self: center;
    }

    #restricciones-popup > div > h2, .restricciones-antes-renta-cita,
    #queRentas-popup > div > h2, .restricciones-antes-renta-cita,
    #queSePuedeHacer-popup > div > h2, .restricciones-antes-renta-cita,
    #beneficios-popup > div > h2, .restricciones-antes-renta-cita{
        text-align: center !important;
    } 

    #queRentas-popup > div > h2, .restricciones-antes-renta-cita,
    #queSePuedeHacer-popup > div > h2, .restricciones-antes-renta-cita{
        margin-top: 4ch;
    }

    #restricciones-popup > div > div:nth-child(3){
        flex-direction: column;
        align-items: center;
    }

    #restricciones-popup > div > div:nth-child(3) > label{
        width: 80vw;
        max-width: unset;
        margin-bottom: 3vw;
    }

    #restricciones-popup > div > div:nth-child(5){
        display: flex;
        flex-direction: column;
    }

    .popups-grid{
        grid-template-columns: 100%;
        max-height: 100%;
    }

    .reset-images{
        font-size: 3vw;
    }

    .btn-select{
        min-width: 60vw;
    }

}
</style>