<template>
    <div class="NotificationsModule-MAIN">
        <div class="nav-notifications" @click="notificationWindowActive = true">
            <img src="../assets/images/icons/campana.svg">
            <div v-if="newNotifications > 0">{{ newNotifications }}</div>
        </div>

        <div class="notifications-emergent" v-if="notificationWindowActive">
            <div><p>Mis notificaciones</p><div @click="notificationWindowActive = false">+</div></div>
            <div v-for="(notification, index) in allNotifications" :key="index" :class="`notification-item ${renderContent(notification.contenido) == false ? 'notif-item-hidden' : ''}`" @click="clickNotification(notification.id, notification.visto)">
                <div v-html="renderContent(notification.contenido)">
                </div>
                <div>
                    <div v-if="notification.visto == 0"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotificationsModule',
    data(){
        return{
            userData: JSON.parse(localStorage.getItem("userData"))?.user,
            allNotifications: [],
            newNotifications: 0,
            notificationWindowActive: false,
            currentURL: window.location.href.split("://")[1].split("/")[0],
        }
    },

    methods: {
        getNotifications(){
            //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://dev-locacionbackoffice.ideeo.mx/api/mis-notifaciones?usuario_id='+this.userData.id, {method: 'GET',
                headers: {
                    "Content-type": "application/json",
                    //"Authorization": this.falseToken //`Bearer ${accessToken}`
                }})
                .then(response => response.json())
                .then(json => {
                    this.allNotifications = [],
                    this.newNotifications = 0,
                    this.allNotifications = json;
                    for(let i of this.allNotifications){
                        if(i.visto == 0){
                            this.newNotifications++;
                        }
                    }
                })
        },

        renderContent(content_){
            let result;

            try {
                content_ = JSON.parse(content_);
            } catch (error) {
                console.log(content_)
                result = content_;
            }

            if(content_.type == "buscoEsto"){
                result = `<a class="delayed-link" href="http://${this.currentURL}/?id=${content_.locationId}/#/detalle" style="color:black;text-decoration:none;">
                        Este es mi código: <br><b>${content_.key}</b>.
                    </a>`;
            }else

            if(content_.type == "chatMsg"){
                if(content_.lastMsg == "MSG_INIT_COUNT"){
                    return false;
                }
                result = `<a class="delayed-link" href="http://${this.currentURL}/?id=${content_.senderId}&profile=chats/#/perfil" style="color:black;text-decoration:none;">
                        <b>Chat</b>
                        <br>${content_.lastMsg}
                    </a>`;
            }else{
                return false;
            }

            return result;
        },

        delayedNavigation(url) {
            setTimeout(() => {
                window.location.href = url;
            }, 1000); // Retraso de 1 segundo (1000 ms)
        },

        clickNotification(notificationId_, visto_){
            console.log(notificationId_);
            this.notificationWindowActive = false;

            if(visto_ == 0){
                //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
                fetch(`https://dev-locacionbackoffice.ideeo.mx/api/baja-notifacion?usuario_id=${this.userData.id}&notificacion_id=${notificationId_}`, {method: 'POST',
                    headers: {
                        "Content-type": "application/json",
                        //"Authorization": this.falseToken //`Bearer ${accessToken}`
                    }})
                    .then(response => response.json())
                    .then(json => {
                        if(json.msg == "Notificacion actualizada"){
                            this.getNotifications();
                        }
                    })
            }
        }
    },

    mounted(){
        this.getNotifications();
        this.baseUrl = window.location.href.split("://")[1].split("/")[0];
    },

    updated() {
        const links = document.querySelectorAll('.delayed-link');
        links.forEach(link => {
            link.addEventListener('click', function(event) {
                event.preventDefault(); // Evita la navegación inmediata
                const href = this.getAttribute('href');
                setTimeout(() => {
                    window.location.href = href; // Navega después del retraso
                }, 500); // Retraso de 1 segundo
            });
        });
    }
}
</script>

<style scoped>
.NotificationsModule-MAIN{
    width: fit-content;
    margin-left: 2ch;
    margin-right: -3ch !important;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nav-notifications > img{
    width: 2.2ch;
}

.nav-notifications > div{
    border-radius: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3ch;
    color: white;
    background-color: #ED2409;
    padding: 0.2ch;
    position: absolute;
    top: 0;
    right: -1ch;
    width: 1.5ch;
    aspect-ratio: 1/1 !important;
    border: 2px solid white;
}


.nav-notifications:hover{
    cursor: pointer;
}

.notifications-emergent{
    position: fixed;
    top: 9ch;
    right: 6ch;
    border-radius: 2ch;
    width: 35ch;
    min-height: 30ch;
    height: fit-content;
    max-height: 80vh;
    background-color: white;
    box-shadow: 0 0.5ch 1ch rgba(0, 0, 0, 0.4);
    overflow-y: auto;
}

.notifications-emergent > div:first-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5ch 0;
    border-bottom: 1px solid lightgray;
    width: 90%;
    margin: auto;
}

.notifications-emergent > div:first-child > p{
    margin: 0;
    font-weight: bolder;
}

.notifications-emergent > div:first-child > div{
    width: 1ch;
    height: 1ch;
    font-size: 3.5ch;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(190, 190, 190);
    rotate: 45deg;
    border-radius: 100vw;
}

.notifications-emergent > div:first-child > div:hover{
    cursor: pointer;
    color: gray;
}

.notification-item{
    border-bottom: 1px solid lightgray;
    width: 90%;
    margin: auto;
    display: flex;
    padding: 0.5ch 0;
    min-height: 4ch;
}

.notification-item > div:nth-child(1){
    width: calc(100% - 2ch);
}

.notification-item > div:nth-child(2){
    width: 2ch;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4ch;
    min-height: unset;
}

.notification-item > div:nth-child(2) > div{
    width: 1ch;
    height: 1ch;
    aspect-ratio: 1/1;
    border-radius: 100vw;
    background-color: #ED2409;
}

.notification-item >>> a{
    width: 100%;
    display: inline-block;
}

.notif-item-hidden{
    display: none;
}
</style>