<template>
    <div class="BuscoEsto-MAIN">

        <h2><img src="../assets/images/lupa.png">Búsquedas urgentes de esta semana</h2>
        <p>
            <b>¡Busco esto! ¿Lo tienes?</b><br>
            Si tu proyecto se aproxima y no has encontrado la locación, puede ser que algún locatario la tenga.
        </p>
        <button @click="viewForm=true" v-if="userData">
            <img src="../assets/images/btn-busco.png">
        </button>
        <br v-else>
        
        <section class="BuscoEsto-carrousel" ref="BuscoEstoCarrousel">
            <img src="../assets/images/icons/arrow.svg" class="BuscoEsto-arrowL" @click="scrollCarrousel(-1)">
            <div v-for="(item, index) in listBuscoEsto" :key="index" class="BuscoEsto-carrousel-elem">
                <div>
                    <img src="../assets/images/icons/arrow-small.svg" class="BuscoEsto-carrousel-btnL" @click="scrollImages(-1, `BuscoEstoCarrousel${item.id}`)">
                    <div :id="`BuscoEstoCarrousel${item.id}`" @click="goToFull(item.id)">
                        <img :src="item.url_foto1" v-if="item.url_foto1 !== '' && item.url_foto1 != null">
                        <img :src="item.url_foto2" v-if="item.url_foto2 !== '' && item.url_foto2 != null">
                        <img :src="item.url_foto3" v-if="item.url_foto3 !== '' && item.url_foto3 != null">
                    </div>
                    <img src="../assets/images/icons/arrow-small.svg" class="BuscoEsto-carrousel-btnR" @click="scrollImages(+1, `BuscoEstoCarrousel${item.id}`)">
                </div>
                <div @click="goToFull(item.id)">
                    <img :src="item.S_URLFotoPerfil"><p>{{ item.titulo }}</p>
                </div>
                <p @click="goToFull(item.id)">{{ item.descripcion }}</p>

                <span style="font-size: 1ch; display: none;">{{ item }}</span>
            </div>
            <img src="../assets/images/icons/arrow.svg" class="BuscoEsto-arrowR" @click="scrollCarrousel(+1)">
        </section>
        
        <main class="BuscoEsto-form" v-if="viewForm">
            <section>
                <p><img :src="profilePicURL">¡BUSCO ESTO! ¿LO TIENES?</p>
                <p>Tu anuncio tendrá vigencia de 6 días</p>

                <img src="../assets/images/close.png" class="close-busco" @click="viewForm=false">
                <div class="input-upload">
                    <input type="file" multiple @change="handleFileSelect">
                    <img src="../assets/images/upload.png">
                </div>

                <div class="img-carrousel-busco">
                    <img v-for="(image, index) in imagesToUpload" :key="index" :src="image" alt="Imagen subida">
                </div>

                <p>
                    <b>Sube lo que buscas</b><br>
                    * Máximo 3 archivos (puedes subir fotos o imágenes que sean reales o ficticias, estas serán usadas de referencia).
                </p>

                <p class="fwidth"><img src="../assets/images/icons/star.svg">¿Para qué usarás la Locación?</p>
                <div class="busca-tipo">
                    <label class="checkboxes-info"><input type="checkbox" v-model="locationUse.S">&nbsp; Shootings</label>
                    <label class="checkboxes-info"><input type="checkbox" v-model="locationUse.F">&nbsp; Filmaciones</label>
                    <label class="checkboxes-info"><input type="checkbox" v-model="locationUse.R">&nbsp; Reuniones</label>
                    <label class="checkboxes-info"><input type="checkbox" v-model="locationUse.E">&nbsp; Eventos</label>
                </div>
                <p class="fwidth"><img src="../assets/images/icons/house-building.svg">Título de tu anuncio <span>{{ title.length }}/50</span></p>
                <input type="text" v-model="title" placeholder="Nombre" maxlength="50" ref="buscoEstoTitle">
                <p class="fwidth"><img src="../assets/images/icons/speaker.svg">Describe la Locación que necesitas <span>{{ description.length }}/400</span></p>
                <textarea v-model="description" placeholder="Describe..." maxlength="400" ref="BuscoEstoDescription"></textarea>
                <p class="micro">* NO SE PERMITE información de nombres de empresas o personas, de locaciones, números telefónicos, links, direcciones de mails o de redes sociales, dirección de calles y números exteriores. Esta información será verificada antes de aprobar tu publicación.</p>
                <p class="tu-publicacion">Tu publicación será vista por nuestra gran comunidad de Locatarios, y si alguien tuviera una opción que te funcione, este te enviará la clave de su locación para que la busques y decidas si tener o no contacto con él en el chat de su anuncio.</p>
                <label class="checkboxes-info"><input type="checkbox" v-model="acceptedTermsConditions">&nbsp; Acepto términos y condiciones</label>
                <button :class="acceptedTermsConditions ? '' : 'disabled'" @click="uploadBuscoEsto()"><img src="../assets/images/btn-busco.png"></button>
                <h3>¡Suerte!</h3>
                <p class="last-p">Esperamos que encuentres lo que buscas.</p>
            </section>             
        </main>
        

    </div>
</template>

<script>
import Compress from 'compress.js';
export default {
    name: 'BuscoEsto',
    data(){
        let userData = JSON.parse(localStorage.getItem('userData'));
        let profilePicURL = "";

        if (userData && userData.user && userData.user.S_URLFotoPerfil) {
            profilePicURL = userData.user.S_URLFotoPerfil;
        }

        return{
            userData: userData,
            viewForm: false,
            profilePicURL: profilePicURL,
            acceptedTermsConditions: false,
            locationUse: {
                S: false,
                F: false,
                R: false,
                E: false,
            },
            title: '',
            description: '',
            imagesToUpload: [],
            listBuscoEsto: [],
            falseToken: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5OWM5OTE0Ny1jOTFkLTQyYTUtYjBmZi0yOGRmZmJlMGRkMDgiLCJqdGkiOiJjMjNhZWM4ODlkN2ZiYTIxY2EyOTI0NjFmN2ZmZDUzN2Y3MjdlYmU4M2I2N2IwNTdlNzNkZTAwNTc0YzE5YzVhYzdkYmIwYmY0YjU4ZGZjZSIsImlhdCI6MTcyMzI0NzUwMS4wMjI5MzMsIm5iZiI6MTcyMzI0NzUwMS4wMjI5MzYsImV4cCI6MTc1NDc4MzUwMS4wMTc3NjYsInN1YiI6IjcyIiwic2NvcGVzIjpbIkNSRUFURSIsIlJFQUQiLCJVUERBVEUiLCJERUxFVEUiXX0.a356A74j7nQrUqMRY0CO7WyYgRBV1nsJdwpkk3TvI-z3ZhYFxi1lUpSEb_0AxmY3789_0Vl1ASk9lgZjjbjOAxX56fxuNAfVCz0o-TJZk8iayauypGbXHW61uOOVkGZOp8hWWUqpKJV5nwZaAoXb3Z5Yi85PIEohrz_X4gOuNxHqDU0wn7XP468b4Ul74MY5SXODtrqD5HZhdkec-3gv6iloWy_evaN-FQDvPS8SUeXAXUOMHMF6Drht9K8cCi05xGURjD03q0CepJlAS5O8IJYp3P84i5B9M_kAQJEUDDjiQciw7HYZBvcu1Rtb1T0svbTyZy8CLr60GtVoyOrrgCXOD-5CIFLkITi4mtxAaJGPpDPjJTcNYLzmgJBHlNMO1g2MrJuoNaNeE_nmUXM1Cvn0xZHOVr1W8lpLdVeFmkiKwo1bd8WPZI_a_dsZwcves_dvdQjL4hiPr2LoLp17ysuYmZCp2bvbriSc8C5b1g3ylEMoFr7NWUvF26ubR-dplvSFyo3TtBG-ohnweIhccMmvkFXlvqNRSlpq7O1cMteJhfxcD4mlktENeJxVHqvfRflAivOj6AVJVRz_erkxUDrU9vzIXv8jgJMbYfQ_qP29Vinh2HbwbjB_Q8tk2JYaUNQ6gvWiB_3Tlb27GYOcJI6cRLMyR4ZI4Mr0MHVDonQ",
        }
    },
    methods: {
        uploadBuscoEsto(){
            console.log("fetch to upload busco esto");
            
            if(this.imagesToUpload.length == 0){
                return;
            }
            const locationUseStr = `${this.locationUse.S?'S':''}${this.locationUse.F?'F':''}${this.locationUse.R?'R':''}${this.locationUse.E?'E':''}`;
            if(locationUseStr == ""){
                return;
            }
            if(this.title.trim() == ""){
                this.$refs.buscoEstoTitle.focus();
                return;
            }
            if(this.description.trim() == ""){
                this.$refs.BuscoEstoDescription.focus();
                return;
            }

            for(let i = this.imagesToUpload.length; i < 3; i++){
                this.imagesToUpload.push("");
            }

            console.log("all passed")
            //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://dev-locacionbackoffice.ideeo.mx/api/busquedas-urgentes',
                {
                    method: 'post',
                    body: JSON.stringify({
                        usuarios_id: JSON.parse(localStorage.getItem("userData")).user.id,
                        url_foto1: this.imagesToUpload[0],
                        url_foto2: this.imagesToUpload[1],
                        url_foto3: this.imagesToUpload[2],
                        uso_locacion: locationUseStr,
                        titulo: this.title.trim(),
                        descripcion: this.description.trim()
                    }),
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": this.falseToken //`Bearer ${accessToken}`
                    }
                })
                .then(response => response.json())
                .then(json => {
                    console.log(json)
                    if(json.msg == "Busquedas Urgentes Agregadas"){
                        console.log("BuscoEsto subido");
                        
                        this.viewForm = false;

                        this.acceptedTermsConditions = false;
                        this.locationUse = {
                            S: false,
                            F: false,
                            R: false,
                            E: false,
                        };
                        this.title = '';
                        this.description = '';
                        this.imagesToUpload = [];
                        this.getList();
                    }
                })
        },

        handleFileSelect(event){
            console.log("handleFileSelect");
            const inputFiles = event.target.files;

            if (!inputFiles || inputFiles.length === 0) {
                console.log("No se seleccionó ningún archivo.");
                return;
            }

            if (inputFiles.length + this.imagesToUpload.length > 3) {
                console.log("¡Máximo 3 imágenes!");
                return;
            }

            let promises = [];
            for (const file of inputFiles) {
                promises.push(this.compressAndUploadImage(file));
            }

            Promise.all(promises)
            .then(() => {
                console.log("SELECTED IMAGES", this.imagesToUpload);
            })
            .catch((error) => {
                console.error("Error al comprimir o cargar las imágenes:", error);
            });
        },

        compressAndUploadImage(file) {
            // Comprimir y subir la imagen, y almacenarla en this.selectedImages
            const compress = new Compress();
            return compress
                .compress([file], {
                    size: 4, // Define el tamaño objetivo en MB
                    quality: 0.75, // Define la calidad de compresión
                })
                .then((data) => {
                    const compressedImage = data[0];
                    const blob = Compress.convertBase64ToFile(compressedImage.data, compressedImage.ext);

                    const formData = new FormData();
                    formData.append("lvl1", "usuarios");
                    formData.append("lvl2", "perfil");
                    formData.append("archivo", blob, file.name);

                    return fetch("https://dev-locacionbackoffice.ideeo.mx/api/sistema_de_carga", {
                        method: "POST",
                        body: formData,
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            this.imagesToUpload.push(data.cargada_en.replace(`/public/`, `https://dev-locacionbackoffice.ideeo.mx/`));
                            console.log("data and images to upload", data, this.imagesToUpload);
                        });
                })
                .catch((error) => {
                    console.error("Error al comprimir la imagen:", error);
                });
        },

        getList(){
            //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
            fetch('https://dev-locacionbackoffice.ideeo.mx/api/lista-busquedas-urgentes', {method: 'GET',
                headers: {
                    "Content-type": "application/json",
                    "Authorization": this.falseToken //`Bearer ${accessToken}`
                }})
                .then(response => response.json())
                .then(json => {
                    console.log("lista de busco esto", json)
                    this.listBuscoEsto = json;
                })
        },

        scrollCarrousel(direction_){
            const elem = this.$refs.BuscoEstoCarrousel;
            elem.scrollLeft += (window.innerWidth/2) * direction_;
        },

        scrollImages(direction_, elem_) {
            const elem = document.getElementById(elem_);
            elem.scrollLeft += elem.offsetWidth * direction_;
        },

        goToFull(targetId_){
            console.log(targetId_)
            let newDirection = window.location.href.split("#")[0].split("?")[0] + `?id=${targetId_}#/buscoesto`;
            window.location.href = newDirection;
            console.log(newDirection);
        }

    },

    mounted(){
        this.getList();
    }
}
</script>

<style scoped>
.BuscoEsto-MAIN{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: -5ch;
}

.BuscoEsto-MAIN > h2{
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 0;
}

.BuscoEsto-MAIN > h2 img{
    width: 2ch;
    margin-right: 0.5ch;
}

.BuscoEsto-MAIN button{
    width: 25ch;
    padding: 0;
    border: none;
    background-color: transparent;
    margin-top: 1ch;
    margin-bottom: 4ch;
}

.BuscoEsto-MAIN button img{
    width: 100%;
}

/* carrousel */
.BuscoEsto-carrousel{
    width: 90vw;
    display: flex;
    align-items: center;
    overflow-x: auto;
    scrollbar-width: none;
    margin-bottom: 5ch;
    padding: 0 5vw;
}

.BuscoEsto-arrowL, .BuscoEsto-arrowR{
    position: absolute;
    width: 1.8ch;
    z-index: 2;
}


.BuscoEsto-arrowL:hover, .BuscoEsto-arrowR:hover{
    cursor: pointer;
    scale: 1.05;
}

.BuscoEsto-arrowL{
    left: 1vw;
}

.BuscoEsto-arrowR{
    right: 1vw;
    rotate: 180deg;
}

.BuscoEsto-carrousel-elem{
    width: 25vw;
    aspect-ratio: 1/1;
    border-radius: 1vw;
    margin: 0 0.5ch;
    position: relative;
}

.BuscoEsto-carrousel-elem > div:nth-child(1){
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
    position: relative;
}

.BuscoEsto-carrousel-elem > div:nth-child(1) > div{
    width: fit-content;
    min-width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;
    scrollbar-width: none;
    border-radius: 1ch;
}

.BuscoEsto-carrousel-elem > div:nth-child(1) > div img{
    min-width: 25vw !important;
    width: 25vw;
    height: 19vw;
    object-fit: cover;
}

.BuscoEsto-carrousel-btnL, .BuscoEsto-carrousel-btnR{
    width: 1.5ch;
    height: 1.5ch;
    position: absolute;
    background-color: #F4F4F4;
    border-radius: 100vw;
    padding: 0.5ch;
}

.BuscoEsto-carrousel-btnL:hover, .BuscoEsto-carrousel-btnR:hover{
    cursor: pointer;
    scale: 1.05;
}

.BuscoEsto-carrousel-btnL{
    rotate: 90deg;
    left: 1ch;
}

.BuscoEsto-carrousel-btnR{
    rotate: -90deg;
    right: 1ch;
}

.BuscoEsto-carrousel-elem > div:nth-child(2){
    display: flex;
    align-items: center;
    margin-top: 0.5ch;
}

.BuscoEsto-carrousel-elem > div:nth-child(2) img{
    width: 2ch;
    height: 2ch;
    border-radius: 100vw;
    margin-right: 1ch;
}

.BuscoEsto-carrousel-elem > div:nth-child(2) p{
    margin: 0;
    font-size: 1.4ch;
}

.BuscoEsto-carrousel-elem > p{
    text-align: left;
    margin: 0;
    margin-top: 0.5ch;
    font-size: 1.25ch;
}

/* section */
.BuscoEsto-form{
    position: fixed;
    top: 0;
    z-index: 20;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    scrollbar-width: thin;
}

.BuscoEsto-form > section{
    background-color: white;
    padding: 1ch 7ch;
    border-radius: 1ch;
    width: 60vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #4D4D4D;
    position: relative;
}

.close-busco{
    position: absolute;
    top: 1ch;
    right: 1ch;
    width: 2ch !important;
    cursor: pointer;
}

.BuscoEsto-form > section > p:nth-child(1){
    display: flex;
    align-items: center;
    font-size: 1.7ch;
    font-weight: bolder;
    margin-bottom: 0;
}

.BuscoEsto-form > section > p:nth-child(1) img{
    width: 2ch;
    margin-right: 0.5ch;
    border-radius: 100vw;
}

.BuscoEsto-form > section > p:nth-child(2){
    margin-top: 0.5ch;
    color: #707070;
}

.input-upload{
    width: 15ch;
    height: 15ch;
    position: relative;
}

.input-upload img{
    width: 15ch;
    cursor: pointer;
}

.input-upload input{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.img-carrousel-busco{
    margin-top: 1.5ch;
    display: flex;
    align-items: center;
}

.img-carrousel-busco > img{
    height: 8ch;
    border-radius: 1ch;
    margin: 0 1ch;
}

.fwidth{
    width: 100%;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
}

.fwidth img{
    max-width: 2ch;
    max-height: 2ch;
    margin-right: 0.5ch;
}

.fwidth span{
    position: absolute;
    right: 0;
}

.busca-tipo{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2ch;
}

.busca-tipo label{
    user-select: none;
}

input[type=text], textarea{
    width: calc(100% - 3ch);
    margin-left: 3ch;
    border-radius: 0.5ch;
    border: 1px solid gray;
    padding: 0.5ch;
}

input[type=text]{
    margin-bottom: 3ch;
}

textarea{
    height: 15ch;
    min-height: 5ch;
    resize: vertical;
}

.micro{
    font-size: 1ch;
    text-align: left;
}

.tu-publicacion{
    text-align: left;
    margin-bottom: 4ch;
}

.checkboxes-info > div{
    display: flex;
    margin: 0.5ch;
}

.checkboxes-info p{
    margin: 0;
    color: #1D7ABE;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.checkboxes-info label{
    margin-right: 1ch;
}

.checkboxes-info input {
    width: 2ch;
    height: 2ch;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ED2409;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkboxes-info input:checked {
    background-color: #ED2409;
}

.BuscoEsto-form button{
    margin-top: 2ch;
    margin-bottom: 0;
}

h3{
    margin-bottom: 0;
}

.last-p{
    margin-top: 0.5ch;
    font-size: 1.2ch;
}

.disabled{
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.3;
}
</style>