<template>
    <div id="AvisoPrivacidad-MAIN">
        <NavLocacion/>

        <main>
            <br><h1>AVISO DE PRIVACIDAD LOCACIÓN.COM.MX</h1>
            <p class="en-el-caso">En el caso de que no esté de acuerdo con los términos y condiciones de uso y<br>privacidad debe abstenerse de acceder o utilizar el portal.</p>

            <div class="aviso" v-html="avisoHTML">
            </div>

            <br>
        </main>

        <FooterLocacion/>
    </div>
</template>

<script>
import FooterLocacion from './FooterLocacion.vue'
import NavLocacion from './NavLocacion.vue'
export default {
    components: { NavLocacion, FooterLocacion },
    name: 'AvisoPrivacidad',
    data(){
        return{
            avisoHTML: ""
        }
    },
    methods:{
        getAviso(){
            try {
                //const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
                fetch('https://dev-locacionbackoffice.ideeo.mx/api/detalle-aviso', {method: 'post',
                    body: JSON.stringify({
                        "id": 1
                    }),
                    headers: {
                        "Content-type": "application/json"
                    }})
                    .then(response => response.json())
                    .then(json => {
                        //console.log(json.detalle.contenido)
                        this.avisoHTML = json.detalle.contenido;
                    })
            } catch (error) {
                console.log(error);
            }
        }
    },
    mounted(){
        this.getAviso();
    }
}
</script>

<style scoped>
    #AvisoPrivacidad-MAIN main{
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #F4F4F4;
    }

    #AvisoPrivacidad-MAIN h1, .en-el-caso{
        text-align: center;
        max-width: 90vw;
    }

    .en-el-caso{
        text-align: center;
        margin-bottom: 4ch;
    }

    .aviso{
        border-radius: 1ch;
        background-color: white;
        width: 80vw;
        margin: 1ch;
        padding: 2ch;
        position: relative;
        height: fit-content;
        transition: all 0.2s;
        margin-bottom: 6ch;
    }

</style>