<template>
    <div class="PerfilUsuario2misLocaciones-MAIN">

        <div class="p8-cel-btn">
            <p @click="filterLocations('activas')" ><button class="btn-inactive" id="p8-btn-cel-activas">Activas</button></p>
            <p @click="filterLocations('en_espera')" ><button class="btn-inactive" id="p8-btn-cel-en_espera">En espera</button></p>
        </div>

        <div  class="p8-ifNotElements">
            <div v-if="filteredLocations.length === 0">No hay locaciones para mostrar.</div>
            <!--<div v-if="filteredLocations.length > 0 && checkIdentificacion == [] || checkIdentificacion == '[]' || checkIdentificacion === null && filterMode == 'en_espera'">Tus locaciones están en espera porque falta completar la información de tu perfil o está en proceso de revisión.</div>-->
        </div>

        <div id="p8-locaciones-tiles"> 

            <div class="tile-misLocaciones" v-for="(location, index) in filteredLocations" :key="index">
                <div>
                    <img src="../assets/images/icons/arrow.svg" class="arrow-left"/>
                    <img class="tile-misLocaciones-type-icon" :src="viewImg(location.tipo_locacion_items)"/>
                    <img src="../assets/images/icons/arrow.svg" class="arrow-right"/>
                    <div class="slider-container" v-html="setSlider(location.Fotos, location.id)" @click="viewLocation(location.id)"></div>
                </div>
                <span v-if="filterMode != 'en_espera'" style="margin-top: -6ch; font-size: 1.2ch;">{{ location.S_Titulo }}<br></span>
                <p style="z-index:3!important;">
                    <span @click="editLocacion(location.id)"><img src="../assets/images/icons/edit.svg">Editar</span>
                    <img src="../assets/images/icons/eye-hide.svg"/>
                </p>
                <p class="tile-locacion-espera" v-if="filterMode == 'en_espera'">
                    <span v-if="checkIdentificacion == [] || checkIdentificacion == '[]' || checkIdentificacion === null ">Tu locación está en espera porque falta completar la información de tu perfil.</span>
                    <span v-else>Tu locación está en espera porque está en proceso de revisión.</span>
                </p>
            </div>

        </div>

        <div id="misLocaciones-popup">
            <div>
                <div class="subirLocacion-close-button" @click="showPopUp(false)">+</div>
                <p id="misLocaciones-popup-text"></p>
                <button @click="showPopUp(false)" id="slPopUpOk-btn-cerrar">Continuar</button>
            </div>
        </div>

    </div>
</template>

<script>
import { htjs, element } from 'alexa-reactor-core';
export default {
    name: 'PerfilUsuario2misLocaciones',
    data(){
        return{
            loadedLocations: false,
            myLocations: [],
            filteredLocations: [],
            sliderSize: 16,
            filterMode: '',
            checkIdentificacion: eval(JSON.parse(localStorage.getItem('userData'))["user"]["S_URLIdentificacion"])
        }
    },

    methods:{

        getCurrentDateTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;
            //return seconds;
        },

        filterLocations(filter_){
            console.log("filter locations")
            this.filterMode = filter_;
            try {
                element("#p8-btn-cel-activas").classList.add("btn-inactive");
                element("#p8-btn-cel-activas").classList.remove("btn-active");
                element("#p8-btn-cel-en_espera").classList.add("btn-inactive");
                element("#p8-btn-cel-en_espera").classList.remove("btn-active");
                element(`#p8-btn-cel-${filter_}`).classList.remove("btn-inactive");
                element(`#p8-btn-cel-${filter_}`).classList.add("btn-active");  
            } catch (error) {
                console.log("Error timeout stylize(), not exists");
            }


            if(filter_ == "activas") filter_ = 1;
            else
            if(filter_ == "en_espera") filter_ = 0;
 
            this.filteredLocations = [];
            for(let i in this.myLocations)
                if(this.myLocations[i].aprobadas == filter_)
                    this.filteredLocations.push(this.myLocations[i]);

            setTimeout(() => {
                try {
                    htjs();
                } catch (error) {
                    console.log("Error timeout htjs(), not exists");
                }
            }, 10);

        },

        setSlider(fotos_, id_){
            let imgs = "";

            for(let i in fotos_){
                imgs += `<div style="
                    background-image:url('${fotos_[i].S_URL}');
                    width: ${this.sliderSize}vw;
                    height: ${this.sliderSize}vw;
                    background-size: cover;
                    background-position: center;
                "></div>`;
            }

            let result = `
            <slider id="sld-${id_}-${this.getCurrentDateTime()}" class="slider-locaciones" style="width:${this.sliderSize}vw; height:${this.sliderSize}vw; border-radius:5px;" interval="4000">
                ${imgs}
            </slider>`;

            return result;
        },

        showPopUp(mode_){
            document.getElementById("misLocaciones-popup").style.display = mode_ == true ? "flex" : "none";
            let checkIdentificacion = eval(JSON.parse(localStorage.getItem('userData'))["user"]["S_URLIdentificacion"]);
            if(checkIdentificacion == [] || checkIdentificacion == "[]" || checkIdentificacion === null){
                document.getElementById("misLocaciones-popup-text").innerHTML = `<strong style="font-size:1.7ch;">Hace falta completar tu perfil</strong><br>para que podamos validar tu Locación.<br>`;
            }else{
                document.getElementById("misLocaciones-popup-text").innerHTML = `<strong style="font-size:1.7ch;">Estamos validando tu Locación.</strong><br>Espera el correo de confirmación.<br>`;
            }
        },

        viewLocation(id_){
            console.log(this.filterMode, id_);
            if(this.filterMode == "en_espera"){
                this.showPopUp(true);
                return;
            }
            //window.location.hash = "/detalle";
            let newDirection = window.location.href.split("#")[0].split("?")[0] + `?id=${id_}/#/detalle`;
            window.location.href = newDirection;
            window.scrollTo(0, 0);
        },

        editLocacion(id_){
            console.log(id_);
            //window.location.hash = "/detalle";
            let newDirection = window.location.href.split("#")[0].split("?")[0] + `?edit=${id_}/#/subir-locacion`;
            window.location.href = newDirection;
            window.scrollTo(0, 0);
            //let result = window.location.href.replace("#/subir-locacion", "#/detalle");
            //window.open(result, '_blank');
        },

        checkWindowWidth() {
            // Obtener el ancho de la ventana
            const windowWidth = window.innerWidth;
            // Actualizar el valor de fontSize en función del ancho de la ventana
            this.sliderSize = windowWidth <= 780 ? 25 : 16;
        },

        viewImg(locationType_){
            // image replacer-
            console.log("Imagen en preview")
            let result = [""];

            try {
                locationType_ = JSON.parse(locationType_);
                console.log(locationType_)
                result = locationType_[0].replaceAll(" ", "").toLowerCase();
            } catch (error) {
                result = "casas";
            }

            if(result == "jardin") result = "jardines";
            if(result == "terraza") result = "terrazas";
            if(result == "casa") result = "casas";
            if(result == "bodega") result = "bodegas";
            if(result == "hospital") result = "hospitales";
            if(result == "hacienda") result = "haciendas";
            if(result == "helipuerto") result = "helipuertos";
            if(result == "local") result = "locales";
            if(result == "alberca") result = "albercas";
            if(result == "oficina") result = "oficinas";
            if(result == "cabaña" || result == "cabañas") result = "house-tree";

            if(result == "") result = "casas";

            console.log(result);
            try {
                return require(`../assets/images/icons/${result}.svg`);
            } catch (error) {
                console.log("No existe img");
                return require(`../assets/images/icons/casas.svg`);
                
            }
        },

    },
    
    mounted(){
        // Get all locations:
        const userId =  JSON.parse(localStorage.getItem('userData')).user.id;
        const accessToken = JSON.parse(localStorage.getItem('userData'))["access_token"];
        fetch('https://dev-locacionbackoffice.ideeo.mx/api/locaciones', {method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }})
            .then(response => response.json())
            .then(json => {
                json = json.dato;
                
                console.log("Ejecutando obtencion de locaciones")
                console.log(json.dato)
                for(let location in json){
                    if(json[location]?.Propietario?.id == userId){
                        console.log(json[location]);
                        this.myLocations.push(json[location]);
                        this.loadedLocations = true;
                    }
                }
                
                if(this.loadedLocations){
                    setTimeout(() => {
                        console.log("re filter")
                        this.filterLocations(this.filterMode);
                    }, 1000);
                }
                //console.log(this.filteredLocations);
            })

        // Verificar el ancho de la ventana cuando el componente se ha montado
        this.checkWindowWidth();
        // Agregar un evento para escuchar cambios en el tamaño de la ventana
        window.addEventListener('resize', this.checkWindowWidth);
    },

    beforeUnmount() {
        // Eliminar el evento de cambio de tamaño de ventana antes de destruir el componente
        window.removeEventListener('resize', this.checkWindowWidth);
    }
}
</script>

<style scoped>
.PerfilUsuario2misLocaciones-MAIN{
    display: flex;
    flex-direction: column;
    width: 100%;
}

#p8-locaciones-tiles{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 3ch;
    width: 100%;
}

#p8-locaciones-tiles > div:not(.p8-cel-btn){
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* tiles */
.tile-misLocaciones{
    display: flex;
    flex-direction: column;
    width: 16vw !important;
    height: 23vw;
    margin-bottom: 3ch;
}

.tile-misLocaciones > div:not(.p8-cel-btn){
    width: 100%;
    height: 100%;
    border-radius: 1ch;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5ch;
}

.tile-misLocaciones > div:not(.p8-cel-btn) > *:not(.tile-misLocaciones-img){
    position: absolute;
}

.tile-misLocaciones-type-icon{
    /*position: absolute;*/
    z-index: 2;
    top: 10% !important;
    right: 1.4vw;
    width: 1.8vw;
    height: 1.8vw !important;
    background-color:    #F4F4F4;
    padding: 0.7ch;
    border-radius: 100vw;
}

.tile-misLocaciones .arrow-left, .tile-misLocaciones .arrow-right{
    /*osition: absolute;*/
    z-index: 2;
    width: 1.5ch;
    height: 1.5ch;
    padding: 0.5ch;
    border-radius: 100vw;
    background-color: #F4F4F4;
    display: none;
}

.tile-misLocaciones:hover .arrow-left, .tile-misLocaciones:hover .arrow-right{
    display: flex;
}

.tile-misLocaciones .arrow-left{
    left: 1ch;
    display: none !important;
}

.tile-misLocaciones .arrow-right{
    right: 1ch;
    transform: rotate(180deg);
    display: none !important;
}

.tile-misLocaciones-img{
    width: 100%;
    height: 100% !important;
    z-index: 1;
}

.tile-misLocaciones p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    cursor: pointer;
}

.tile-misLocaciones p > img:hover{
    cursor: pointer;
    filter: brightness(0);
}

.tile-misLocaciones span{
    display: flex;
    align-items: center;
    width: fit-content;
}

.tile-misLocaciones span:hover{
    text-decoration: underline;
}

.tile-misLocaciones span img{
    width: 1.6ch;
    margin-right: 0.6ch;
}

.tile-locacion-espera{
    font-size: 1ch;
    margin-bottom: 1ch;
}

/* slider */
.slider-container{
    width: 16vw;
    height: 16vw;
    cursor: pointer;
}

.slider-locaciones{
    border-radius: 5px;
    width: 16vw !important;
    height: 16vw !important;
    cursor: pointer;
}

.slider-locaciones > div{
    width: 16vw !important;
}

.div-of-slider{
    width: 16vw !important;
    height: 16vw !important;
    background-size: cover !important;
    background-position: center !important;
    border: 1px solid red;
}

.p8-cel-btn{
    display: none;
}

.p8-ifNotElements{
    color: gray;
    text-align: center;
    width: 100vw !important;
    max-width: unset;
    padding-top: 2ch;
}

.p8-ifNotElements > div{
    width: 50vw;
    margin-bottom: 1ch;
}


#misLocaciones-popup{
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
    z-index: 3;
    display: none;
}

.subirLocacion-close-button{
    position: absolute;
    top: 0.3ch;
    right: 0.3ch;
    color: gray;
    transform: rotate(45deg);
    font-size: 3.5ch;
    width: 1.5ch;
    height: 1.5ch;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    text-align: center;
    cursor: pointer;
}

#misLocaciones-popup > div{
    position: relative;
    background-color: white;
    width: 40vw;
    height: fit-content;
    min-height: fit-content;
    display: inherit;
    flex-direction: inherit;
    align-items: inherit;
    justify-content: inherit;
    border-radius: 1ch;
    padding: 6ch 3ch;
    text-align: center;
}

#misLocaciones-popup p{
    color: rgb(65, 65, 65);
    font-size: 1.7ch;
}

#misLocaciones-popup button{
    background: linear-gradient(#FF4C0B, #FF0A46);
    color: white;
    padding: 2ch 4ch;
    font-size: 12px;
    border: none;
    border-radius: 100vw;
    text-transform: uppercase;
    min-width: 210px;
    font-weight: bold;
    margin-bottom: 2ch;
    width: fit-content;
    align-self: center;
    margin-top: 3ch;
}
/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){
    .PerfilUsuario2misLocaciones-MAIN{
        display: flex;
        flex-direction: column;
    }

    .p8-cel-btn{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80vw;
    }

    .p8-cel-btn > p{
        width: 40%;
        text-align: center;
    }

    #p8-locaciones-tiles{
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: center;
        align-items: center;
    }

    .tile-misLocaciones{
        width: 100% !important;
        height: 30vw;
    }

    .tile-misLocaciones-type-icon{
        right: 0;
        left: 0;
        margin: auto;
        margin-right: 10vw;
    }  

    .slider-container{
        width: 25vw;
        height: 25vw;
    }

    .slider-locaciones{
        border-radius: 5px;
        width: 25vw !important;
        height: 25vw !important;
    }

    .slider-locaciones > div{
        width: 25vw !important;
    }

    .div-of-slider{
        width: 25vw !important;
        height:25vw !important;
    }

    .tile-misLocaciones p{
        width: 25vw;
        font-size: 3.4vw;
    }

    .p8-ifNotElements{
        width: 100% !important;
    }

    #misLocaciones-popup > div{
        width: 75vw;
        padding: 5vw;
    }  
    
    .tile-misLocaciones{
        margin-bottom: 7ch;
    }

    .tile-misLocaciones > p:not(.tile-locacion-espera){
        padding-top: 1ch;
        margin-top: 3ch;
    }

    .tile-locacion-espera{
        font-size: 1.2ch !important;
        margin-bottom: 1ch;
    }
}
</style>